import React, { useState } from "react";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import { useNavigate } from "react-router-dom";

const MultistepForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => setCurrentStep(currentStep - 1);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepOne formData={formData} handleChange={handleChange } nextStep={nextStep}  prevStep={prevStep}  currentStep= {currentStep} />;
      case 2:
        return <StepTwo formData={formData} handleChange={handleChange } nextStep={nextStep}  prevStep={prevStep}  currentStep= {currentStep} />;
      case 3:
        return <StepThree formData={formData} handleChange={handleChange } nextStep={nextStep}  prevStep={prevStep}  currentStep= {currentStep} />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <div className="card p-4">
        {renderStep()}
      </div>
    </div>
  );
};

export default MultistepForm;
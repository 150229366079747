import axios from "axios";
import * as Config from "../../Utils/Config";


export async function Create_Package(data) {
    try {
        const res = await axios.post(`${Config.base_url}createpackage`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function view_Package(data) {
    try {
        const res = await axios.post(`${Config.base_url}PackageList`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Singale_Package(data) {
    try {
        const res = await axios.post(`${Config.base_url}GetPackageById`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function update_Package(data) {
    try {
        const res = await axios.post(`${Config.base_url}updatePackage`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function delete_Package(data) {
    try {
        const res = await axios.post(`${Config.base_url}deletePackage`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}





export async function get_Singale_Service(data) {
    try {
        const res = await axios.post(`${Config.base_url}service/GetserviceById`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function delete_Package_Service(data) {
    try {
        const res = await axios.post(`${Config.base_url}package/deletePackageService`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function Update_PackageService_Rules(data) {
    try {
        const res = await axios.post(`${Config.base_url}package/UpdatePackageServiceRules`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function last_Five_Packages(data) {
    try {
        const res = await axios.post(`${Config.base_url}package/lastfivepackage`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}










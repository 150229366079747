import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import ReusableModal from '../../../ExtraComponents/ReusableModal';
import { allServiceList, deleteService} from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import { getServiceType, updateService, getServiceApplicable } from '../../../ReduxStore/Slice/Pricing/ServiceSlice'
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Trash2 } from 'lucide-react';


const AllService = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [getServiceData, setServiceData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [serviceApplicableData, setServiceApplicableData] = useState([]);
  const [priceChange, setPriceChange] = useState([]);
  const [InputValue, setInputValue] = useState('');
  const [filterArr, setFilterArr] = useState({ serviceType: '', vat: '', chargeBasisType: '' })


  useEffect(() => {
    ServiceType();
    ServiceApplicable();
  }, []);

  useEffect(() => {
    getAllService();
  }, [InputValue]);

  const ServiceType = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceType(req)).unwrap();
      if (res.status) {
        setServiceTypeData(res.data);
      } else {
        setServiceTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ServiceApplicable = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceApplicable(req)).unwrap();
      if (res.status) {
        setServiceApplicableData(res.data);
      }
      else {
        setServiceApplicableData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllService = async (type) => {
    const req = {
      page: 1,
      pageSize: 100,
      servicename: "",
      servicetype_id: type == 1 ? "" : filterArr.serviceType,
      vat: type == 1 ? "" : filterArr.vat.length == 2 ? "" : filterArr.vat[0],
      price_type: type == 1 ? "" : filterArr.chargeBasisType.length == 2 ? "" : filterArr.chargeBasisType[0],
    }
    await dispatch(allServiceList(req)).unwrap()
      .then((response) => {
        if (response.status) {
          const data = response.data.filter((item) => item.name.toLowerCase().includes(InputValue.toLowerCase()));
          setServiceData(InputValue ? data : response.data);
          setShowModal(false);
        } else {
          setServiceData([]);
          setShowModal(false)
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const EditService = async (e, row, type) => {

    let newStatus
    if (type == "ServiceType") {
      newStatus = e.target.value;
    }
    else if (type == "VAT") {
      newStatus = e.target.value;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change Service Type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let req
          if (type == "ServiceType") {
            req = { id: row.id, name: row.name, servicetype_id: newStatus };
          }
          else if (type == "Applicable") {
            const selected = e
            req = { id: row.id, name: row.name, applicable_to: selected.map((item) => item.value) };
          }
          else if (type == "VAT") {
            req = { id: row.id, name: row.name, vat: newStatus };
          }
          else if (type == "Price") {
            // const price = e
            req = {
              id: row.id,
              name: row.name,
              servicechargebases: priceChange
            }
          }

          const res = await dispatch(updateService(req)).unwrap();
          if (res.status) {
            Swal.fire({
              title: "Success",
              text: res.message,
              icon: "success",
              timer: 1000,
              showConfirmButton: false,
            });
            getAllService();
            if (type == "Price") {

              handleRowExpandToggle(row.id);
            }
          } else {
            Swal.fire({
              title: "Error",
              text: res.message,
              icon: "error",
              confirmButtonText: "Ok",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the status.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Status change was not performed",
          icon: "error",
          timer: 1000,

          confirmButtonText: "Ok",
        });
      }
    });
  };

  const handleRowExpandToggle = (rowId) => {
    setExpandedRowId(expandedRowId === rowId ? null : rowId);
  };

  const columns = [
    {
      name: "Service Name",
      selector: (row) =>
        < div title={row.name || ""} className='w-100'>
          <div className='w-100'>
            {row.name || ""}
          </div>
         {row.price_type == "2" && <div  style={{ border: "1px solid #EAA7EF", borderRadius: "14px", padding: "2px 5px",lineHeight:'12px' }} className='mt-1 text-center'>
            <small className="text-pink text-center" >{row.price_type == "2" ? "Pricing Rule" : ""}</small>
          </div>} 
        </div>,

      sortable: true,
      width: '15%',
    },
    {
      name: "Service Type",
      cell: (row) => (
        <div className='w-100'>
          {
            <select
              className="form-select border formselect"
              id="floatingSelect"
              aria-label="Floating label select example"
              value={row.servicetype_id}
              onChange={(e) => EditService(e, row, "ServiceType")}
            >
              {serviceTypeData.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}

            </select>


          }
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '18%',
      sortable: true,
    },
    {
      name: "Applicable To",
      cell: (row) => {
        const selectedOptions = row.ServiceApplicables?.map((applicableId) => {
          const applicable = serviceApplicableData?.find((data) => data.id === applicableId);
          return applicable ? { label: applicable.name, value: applicable.id } : null;
        }).filter(Boolean);
        return (
          <div className='w-100'>
            <div className='d-flex align-items-center'>
              <div className="mb-2 mt-2 w-100">
                {console.log("selectedOptions", selectedOptions)}
                <Select
                  classNames="multiselect border"
                  options={serviceApplicableData?.map((data) => ({
                    label: data.name,
                    value: data.id,
                  }))}
                  isMulti
                  isClearable={false}
                  className="basic-multi-select border table-select"
                  style={{ border: 'none' }}
                  value={selectedOptions}
                  onChange={(selected) => {
                    if (selected && selected.length === 0) {
                      return;
                    }
                    EditService(selected, row, "Applicable");
                  }}
                  placeholder="Select options"
                />

              </div>
            </div>
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width: '25%',
    },
    {
      name: "VAT",
      cell: (row) => (
        <div className='w-100'>
          <select
            className="form-select formselect border"
            value={row.vat}
            onChange={(e) => EditService(e, row, "VAT")}
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      width: '10%'
    },
    {
      name: "Charge Basis",
      selector: (row) => row.price_type == "1" ? "Fixed Price" : "Variable" || "-",
      sortable: true,
      width: '18%'
    },
    {
      name: "Price",
      cell: (row) => (
        <div className='w-100 '>
          {row.price_type == "1" ? row.price :
            <button
              className="btn btn-link"
              onClick={() => handleRowExpandToggle(row.id)}
            >
              {expandedRowId === row.id ? (
                <i className="text-pink bx bx-minus"></i>
              ) : (
                <i className=" text-pink ri-add-fill"></i>
              )}
            </button>
          }
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      witdh: '14%'
    },
    {
      name: "Action",
      cell: (row) => (
        <div className='w-100 '>
          {row.PackageServices.length > 0 ? "" :
          <Trash2 onClick={()=>handleDeleteService(row)}/>
          }
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      sortable: true,
      witdh: '14%'
    },

  ];


  const handleDeleteService = async (row) => {
    
     Swal.fire({
             title: "Are you sure?",
             text: "Do you want to delete this service?",
             icon: "warning",
             showCancelButton: true,
             confirmButtonText: "Yes, change it!",
             cancelButtonText: "No, cancel",
           }).then(async (result) => {
             if (result.isConfirmed) {
               const req = {  id : row.id };
               await dispatch(deleteService(req)).unwrap()
               .then((response) => {
                 if (response.status) {
                   Swal.fire({
                     title : "Success",
                     text : "Service Deleted Successfully",
                     icon : "success",
                     timer : 1500,
                     timerProgressBar : true
                    }
                   );
                   getAllService()
                 }
               })
               .catch((error) => {
                console.error("Error deleting package:", error);
               });
             } else if (result.dismiss === Swal.DismissReason.cancel) {
               return
             }
           });
  
    }

    

  const expandableRowsComponent = (row) => (
    <>
      {
        row?.ServiceChargeBases?.map((item, index) => (

          <div className='expandableContent' >
            <div className='expandableContent-div'>
              {/* Charge Basis Heading */}


              {/* Table for Charge Type and Price */}
              <table className='table table-borderless'>
                <thead>
                  <tr>
                    <td style={{ width: "55%" }}><h6 className='text-pink mb-0'>Charge Basis {index + 1}</h6>
                      <hr className='my-2' />
                      <b className='mb-0 fw-300'>{item?.ChargeBasis?.name}</b></td>
                    <td style={{ width: "10%" }}></td>
                    <td className='text-pink' style={{ verticalAlign: 'top', color: '#ff00aa' }}>Price</td>
                  </tr>
                </thead>
                <tbody>
                  {item?.ServiceChargeBasisValues?.map((chargeType, idx) => (
                    <tr key={chargeType?.id || idx}>
                      {/* Charge Type */}
                      <td className='align-middle' >{chargeType?.ChargeType?.name}</td>
                      {/* Price */}
                      <td></td>
                      <td>
                        <input
                          type="text"
                          
                          className="mb-2 form-control"
                          onChange={(e) =>
                            handlePriceChange(
                              e.target.value,
                              row.id,
                              item.ChargeBasis.id,
                              chargeType.ChargeType.id,
                              item.operator,
                              chargeType.id
                            )
                          }
                          // disabled={item?.ChargeBasis?.input_required == 0}
                          disabled
                          value={getPriceValue(
                            row?.id,
                            item?.ChargeBasis?.id,
                            chargeType?.ChargeType?.id,
                            chargeType
                          )}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <div className='expandableContent-div'>

            
              <div>
                <h6 className='text-pink mb-0'>Charge Basis  {index + 1}</h6>
                <hr />
                {item.ChargeBasis.name}
                {item?.ServiceChargeBasisValues?.map((chargeType) => (
                  <>
                    <p>{chargeType.ChargeType.name}</p>
                  </>
                ))}
              </div>
              <div>
                <p className='text-pink mb-5'>Price</p>
                {item?.ServiceChargeBasisValues?.map((chargeType) => (
                  <>
                    <div>
                      <input
                        type="text"
                        className="mb-2 form-control"
                        onChange={(e) =>
                          handlePriceChange(
                            e.target.value,
                            row.id,
                            item.ChargeBasis.id,
                            chargeType.ChargeType.id,
                            item.operator,
                            chargeType.id
                          )}
                        disabled={item?.ChargeBasis?.input_required == 0}
                        value={getPriceValue(row?.id, item?.ChargeBasis?.id, chargeType?.ChargeType?.id, chargeType)}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div> */}
          </div>
        ))
      }
      {/* <div className='expandableContent'>
        <div className='expandableContent-div d-flex justyfy-content-end'>
          <button className='btn btn-pink' onClick={() => EditService(priceChange, row, "Price")}>Save</button>
        </div>
      </div> */}
    </>
  );

  const handlePriceChange = (chargetype_rule_value, rowId, chargebasis_id, chargetype_rule_id, operator, servicechargebasisvalues_id) => {

    setPriceChange((prevPriceChange) => {
      const existingEntryIndex = prevPriceChange.findIndex(
        (item) =>
          item.id === rowId &&
          item.chargebasis_id === chargebasis_id &&
          item.chargetype_rule_id === chargetype_rule_id
      );
      if (existingEntryIndex !== -1) {
        const updatedPriceChange = [...prevPriceChange];
        updatedPriceChange[existingEntryIndex].chargetype_rule_value = chargetype_rule_value;
        return updatedPriceChange;
      } else {
        return [
          ...prevPriceChange,
          { id: rowId, chargebasis_id, chargetype_rule_id, chargetype_rule_value, operator: operator, servicechargebasisvalues_id: servicechargebasisvalues_id },
        ];
      }
    });
  };


  const getPriceValue = (rowId, chargebasis_id, chargetype_rule_id, chargeType) => {

    const match = priceChange && priceChange.find(
      (item) =>
        item.id == rowId &&
        item.chargebasis_id == chargebasis_id &&
        item.chargetype_rule_id == chargetype_rule_id
    );

    return match ? match.chargetype_rule_value : chargeType.value;
  };

  const serviceTypeFilterArr = [
    { value: '1', label: "Recurring" },
    { value: '2', label: "One-off" },
    { value: '3', label: "Both" },
    { value: '4', label: "With Package Only" }
  ]

  const vatFilterArr = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" }
  ]

  const chargeBasisFilterArr = [
    { value: "1", label: "Fixed" },
    { value: "2", label: "Variable" }
  ]

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">Services</h4>
        </div>
        <div className="col-auto align-self-center">
          <Link to="/admin/pricing/create-services" className="btn btn-pink" id="Dash_Date">
            <span className="ay-name" id="Day_Name">Add New Service</span>
          </Link>
        </div>
      </div>

      <section className="section dashboard">
        <div className="row">
          <data className='col-lg-3 datatable-search'>
            <input type="text"
              className='search p-2 icon'
              placeholder='Search here'
              value={InputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </data>
          <div className='col-lg-5'>
            <button className='btn w-auto filter-btn p-0' onClick={handleShowModal}>
              <i className="bi bi-filter fs-3 text-pink"></i>
            </button>
            <button className='btn btn-pink ms-4' onClick={() => {
              setFilterArr({ serviceType: '', vat: '', chargeBasisType: '' })
                ; getAllService(1)
            }}>
              Clear Filter
            </button>
          </div>
          <div>

          </div>
        </div>
        < div className='expandable-table'>
          <Datatable
            columns={columns}
            data={getServiceData}
            filter={false}
            expandedRowId={expandedRowId}
            expandableRowsComponent={expandableRowsComponent}
          />
        </div>

      </section>
      <ReusableModal
        show={showModal}
        onClose={handleCloseModal}

        title={<><i className="bi bi-filter" style={{ marginRight: "8px" }}></i>Filters</>}
        body={
          <div className='service-filter ps-2'>
            <div className="row mb-5">
              <p className="text-semidark mb-2 font-14">Service Type</p>
              <div className="col-sm-12 d-flex">
                {serviceTypeFilterArr.map((item) => (
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={filterArr.serviceType.includes(item.value)}
                      value={item.value}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFilterArr((prev) => ({ ...prev, serviceType: [...prev.serviceType, e.target.value] }))
                        } else {
                          setFilterArr((prev) => ({ ...prev, serviceType: prev.serviceType.filter((type) => type !== e.target.value) }))
                        }
                      }}
                    />
                    <label className="form-check-label ms-1" >
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mb-5">
              <p className="text-semidark mb-3 font-14">VAT</p>
              <div className="col-sm-12 d-flex">
                {
                  vatFilterArr.map((item) => (
                    <div className="form-check me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={filterArr.vat.includes(item.value)}
                        value={item.value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilterArr((prev) => ({ ...prev, vat: [...prev.vat, e.target.value] }))
                          } else {
                            setFilterArr((prev) => ({ ...prev, vat: prev.vat.filter((type) => type !== e.target.value) }))
                          }
                        }}
                      />
                      <label className="form-check-label ms-1" >
                        {item.label}
                      </label>
                    </div>
                  ))
                }

              </div>
            </div>
            <div className="row mb-5">
              <p className="text-semidark mb-3 font-14">Charge Basis</p>
              <div className="col-sm-12 d-flex">
                {chargeBasisFilterArr.map((item) => (
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={item.value}
                      defaultChecked={filterArr.chargeBasisType.includes(item.value)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFilterArr((prev) => ({ ...prev, chargeBasisType: [...prev.chargeBasisType, e.target.value] }))
                        } else {
                          setFilterArr((prev) => ({ ...prev, chargeBasisType: prev.chargeBasisType.filter((type) => type !== e.target.value) }))
                        }
                      }}
                    />
                    <label className="form-check-label ms-1" >
                      {item.label}
                    </label>
                  </div>
                ))}

              </div>
            </div>
            <div className='row'>

            </div>
          </div>
        }
        footer={
          <>
            <button className='btn btn-outline-secondary' onClick={handleCloseModal}>Cancel</button>
            <button className='btn btn-pink' onClick={() => getAllService()}>Apply</button>
          </>
        }
      />
    </div>
  );
};

export default AllService;

import axios from "axios";
import * as Config from "../../Utils/Config";


export async function create_Email_Temp(data) {
    try {
        const fromData = new FormData();
        fromData.append('agent_id', data.agent_id);
        fromData.append('name', data.formData.templateName);
        fromData.append('description', data.formData.description);
        fromData.append('template_trriger_id', data.formData.triggerEvent);
        fromData.append('subject', data.formData.subjectLine);
        fromData.append('bcc_mail', data.formData.bccEmail);
        fromData.append('reply_to', data.formData.replyTo);
        fromData.append('body', data.formData.body);
        data?.formData.attachments?.forEach((file) => {
            fromData.append('attachment', file);
        });

        const res = await axios.post(`${Config.base_url}email/create_email_template`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'maxContentLength': 'Infinity',
                'maxBodyLength': 'Infinity'
            },
        })
        return await res?.data;
    }

    catch (err) {
        return await err;
    }
}

export async function update_Email_Temp(data) {
    try {
        const fromData = new FormData();
        fromData.append('agent_id', data.agent_id);
        fromData.append('name', data.formData.templateName);
        fromData.append('description', data.formData.description);
        fromData.append('template_trriger_id', data.formData.triggerEvent);
        fromData.append('subject', data.formData.subjectLine);
        fromData.append('bcc_mail', data.formData.bccEmail);
        fromData.append('reply_to', data.formData.replyTo);
        fromData.append('body', data.formData.body);
        fromData.append('id', data.id);    
        data?.formData.attachments?.forEach((file) => {
            fromData.append('attachment', file);
        });

        const res = await axios.post(`${Config.base_url}email/update_email_template`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'maxContentLength': 'Infinity',
                'maxBodyLength': 'Infinity'
            },
        })
        return await res?.data;
    }

    catch (err) {
        return await err;
    }
}

export async function get_Trigger_Events(data) {
    try {
        const res = await axios.post(`${Config.base_url}trriger_event`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_ReplyTo(data) {
    try {
        const res = await axios.post(`${Config.base_url}replyto`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function get_Tags(data) {
    try {
        const res = await axios.post(`${Config.base_url}gettemplatetags`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function all_Email_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}email/template_list`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function delete_Email_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}email/delete`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}


export async function get_Single_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}email/get_template_by_id`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}









import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    create_Quote_Temp,
    get_Tags,
    get_Common_Templet,
    get_Templet_Catagry,
    all_Quote_Templet,
    delete_Quote_Templet,
    get_ReplyTo,
    create_Common_Templet,
    get_Single_Templet,
    update_Quote_Temp
} from '../../../Service/Templets/QuotesTempletService'
// const UserId = JSON.parse(localStorage.getItem("agent_id"));
const UserId = JSON.parse(localStorage.getItem("details"))?.id;

export const createQuoteTemp = createAsyncThunk("create_quote_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Quote_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getTags = createAsyncThunk("gettemplatetags", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Tags(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getCommonTemplet = createAsyncThunk("getcommontemplates", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Common_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getTempletCategary = createAsyncThunk("gettemplatecategory", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Templet_Catagry(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const allQuoteTemplet = createAsyncThunk("template_list", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await all_Quote_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const deleteQuoteTemplet = createAsyncThunk("delete", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await delete_Quote_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getReplyTo = createAsyncThunk("replyto", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_ReplyTo(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const createCommonTemplet = createAsyncThunk("create_common_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Common_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getSingleTemplet = createAsyncThunk("get_template_by_id", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Single_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const updateQuoteTemp = createAsyncThunk("update_quote_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await update_Quote_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

const ChangeBasisSlice = createSlice({
    name: "ChangeBasisSlice",
    initialState: {
        isLoading: false,
        isError: false,
        createquotetemp: [],
        gettags: [],
        getcommontemplet: [],
        gettempletcatagary: [],
        allquotetemplet: [],
        deletequotetemplet: [],
        createcommontemplet: [],
        getreplyto: [],
        getsingletmplet: [],
        updatequotetemplet: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createQuoteTemp.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createQuoteTemp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createquotetemp = action.payload;
            })
            .addCase(createQuoteTemp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getTags.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettags = action.payload;
            })
            .addCase(getTags.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getCommonTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getCommonTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getcommontemplet = action.payload;
            })
            .addCase(getCommonTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getTempletCategary.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTempletCategary.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettempletcatagary = action.payload;
            })
            .addCase(getTempletCategary.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(allQuoteTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(allQuoteTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allquotetemplet = action.payload;
            })
            .addCase(allQuoteTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(deleteQuoteTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(deleteQuoteTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deletequotetemplet = action.payload;
            })
            .addCase(deleteQuoteTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getReplyTo.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getReplyTo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getreplyto = action.payload;
            })
            .addCase(getReplyTo.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(createCommonTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createCommonTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createcommontemplet = action.payload;
            })
            .addCase(createCommonTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getSingleTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getSingleTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getsingletmplet = action.payload;
            })
            .addCase(getSingleTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(updateQuoteTemp.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateQuoteTemp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updatequotetemplet = action.payload;
            })
            .addCase(updateQuoteTemp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });

    },

});

export default ChangeBasisSlice;

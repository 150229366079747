import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { useNavigate } from "react-router-dom";
import {
  getBasisType,
  createChargeBasis,
} from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import sweatalert from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import {
  EMAPTY_TAGS_ERROR,
  EMPTY_CHARGE_BASIS_TYPE_ERROR,
  EMPTY_CHARGE_BASIS_NAME_ERROR,
  EMPTY_NAME_OF_UNIT_ERROR,
  EMAPTY_BAND_VALUE_ERROR,
  EMAPTY_PRICE_PER_UNIT_ERROR,
  EMPTY_MIN_BAND_BASIS_ERROR,
  EMPTY_MAX_BAND_BASIS_ERROR,
  EMPTY_FREQUENCY_VALUE_ERROR,
  EMPTY_FREQUENCY_NAME_ERROR,
  EMPTY_YESNO_ERROR,
} from "../../../Utils/Common_Error";

import { ScrollToViewFirstError } from "../../../Utils/CommonFun";

const CreateChargebasis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getBasisTypeData, setGetBasisTypeData] = useState([]);
  const [brandRangeArr, setBrandRangeArr] = useState([{ id: uuidv4(), name: "", value: "0.00" }]);
  const [frequencyArr, setFrequencyArr] = useState([{ id: uuidv4(), name: "", value: "" }]);
  const [unitName, setUnitName] = useState({ name: "", value: "" });
  const [yesNoValue, setYesnoValue] = useState([{ name: "Yes", value: "" }, { name: "No", value: "" }]);


  const [errors, setErrors] = useState({
    perUnit: {},
    brandRange: {},
    frequency: {},
    yesNo: {},
  });

  useEffect(() => {
    GetBasisType();
  }, []);

  const GetBasisType = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getBasisType(req)).unwrap();
      if (res.status) {
        setGetBasisTypeData(res.data);
      } else {
        setGetBasisTypeData([]);
      }
    } catch (err) {
      console.log("Error in getting Basis Type", err);
    }
  };

  const handleFrequencyChange = (index, type, value) => {
    const currentItem = frequencyArr[index];
    const name = type === "name" ? value : currentItem?.name || "";
    const val = type === "value" ? value : currentItem?.value || "";

    const updatedArray = frequencyArr.map((item, idx) =>
      idx === index ? { ...item, name: name, value: val } : item
    );
    setFrequencyArr(updatedArray);
  };

  const handleRangeChange = (index, type, value) => {
    const currentItem = brandRangeArr[index];
    const minValue =
      type === "minValue" ? value : currentItem?.name?.split("-")[0] || "";
    const maxValue =
      type === "maxValue" ? value : currentItem?.name?.split("-")[1] || "";
    const val = type === "value" ? value : currentItem?.value || "0.00";
    const newRange = `${minValue}-${maxValue}`;
    const updatedArray = brandRangeArr.map((item, idx) =>
      idx === index ? { ...item, name: newRange, value: val, } : item
    );
    setBrandRangeArr(updatedArray);
  };

  const removeBasisRange = (index) => {
    const newBrandRange = brandRangeArr.filter((_, idx) => idx !== index);
    setBrandRangeArr(newBrandRange);
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (
        updatedErrors.brandRange &&
        typeof updatedErrors.brandRange === "object"
      ) {
        delete updatedErrors.brandRange[index];
      }
      return updatedErrors;
    });
  };

  const removeFrequency = (index) => {
    setFrequencyArr(frequencyArr.filter((_, idx) => idx !== index));
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (
        updatedErrors.frequency &&
        typeof updatedErrors.frequency === "object"
      ) {
        delete updatedErrors.frequency[index];
      }
      return updatedErrors;
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      basistypes_id: 1,
      tags: "",
      status: 0,
      input_required: 0,
    },
    validate: (values) => {
      let errors = {};
      if (!values?.name?.trim()) {
        errors.name = EMPTY_CHARGE_BASIS_NAME_ERROR;
      }
      if (!values?.basistypes_id) {
        errors.basistypes_id = EMPTY_CHARGE_BASIS_TYPE_ERROR;
      }
      // if (!values?.tags?.trim()) {
      //   errors.tags = EMAPTY_TAGS_ERROR;
      // }
      return errors;
    },
    onSubmit: async (values) => {
      let formIsValid = true;
      const newErrors = {
        perUnit: {},
        brandRange: {},
        frequency: {},
        yesNo: {},
      };
      if (
        formik.values.basistypes_id == 1 ||
        formik.values.basistypes_id == 2
      ) {
        if (!unitName.name) {
          newErrors.perUnit.unitName = EMPTY_NAME_OF_UNIT_ERROR;
          formIsValid = false;
        }

        if (!unitName.value) {
          newErrors.perUnit.unitValue = EMAPTY_PRICE_PER_UNIT_ERROR;
          formIsValid = false;
        }
      }
      if (formik.values.basistypes_id == 3) {
        brandRangeArr.forEach((item, index) => {
          if (item.name.split("-")[0] == "") {
            newErrors.brandRange = newErrors.brandRange || [];
            newErrors.brandRange[index] = {
              ...newErrors.brandRange[index],
              minValue: EMPTY_MIN_BAND_BASIS_ERROR,
            };
            formIsValid = false;
          }
          if (item.name.split("-")[1] == "") {
            newErrors.brandRange = newErrors.brandRange || [];
            newErrors.brandRange[index] = {
              ...newErrors.brandRange[index],
              maxValue: EMPTY_MAX_BAND_BASIS_ERROR,
            };
            formIsValid = false;
          }

          if (!item.value) {
            newErrors.brandRange = newErrors.brandRange || [];
            newErrors.brandRange[index] = {
              ...newErrors.brandRange[index],
              value: EMAPTY_BAND_VALUE_ERROR,
            };
            formIsValid = false;
          }
        });
      }
      if (formik.values.basistypes_id == 4) {
        frequencyArr.forEach((item, index) => {
          if (!item.name) {
            newErrors.frequency = newErrors.frequency || [];
            newErrors.frequency[index] = {
              ...newErrors.frequency[index],
              name: EMPTY_FREQUENCY_NAME_ERROR,
            };
            formIsValid = false;
          }
          if (!item.value) {
            newErrors.frequency = newErrors.frequency || [];
            newErrors.frequency[index] = {
              ...newErrors.frequency[index],
              value: EMPTY_FREQUENCY_VALUE_ERROR,
            };
            formIsValid = false;
          }
        });
      }
      if (formik.values.basistypes_id == 5) {
        if (!yesNoValue[0].value) {
          newErrors.yesNo.Yes = EMPTY_YESNO_ERROR;
          formIsValid = false;
        }
        if (!yesNoValue[1].value) {
          newErrors.yesNo.No = EMPTY_YESNO_ERROR;
          formIsValid = false;
        }

      }

      if (!formIsValid) {
        setErrors(newErrors);
        return;
      }

      const req = {
        token: token,
        name: values.name,
        basistypes_id: Number(values.basistypes_id),
        tags: values.tags,
        status: values.status == 1 ? "1" : "0",
        input_required: values.input_required == 1 ? "1" : "0",
        basis_type:
          formik.values.basistypes_id == 1 || formik.values.basistypes_id == 2
            ? [unitName]
            : formik.values.basistypes_id == 3
              ? brandRangeArr
              : formik.values.basistypes_id == 4
                ? frequencyArr
                : yesNoValue,
      };



      await dispatch(createChargeBasis(req)).unwrap()
        .then((res) => {
          console.log("res", res);
          if (res.status) {
            sweatalert.fire({
              title: res.msg,
              icon: "success",
              timer: 3000,
            });
            navigate("/admin/pricing/all-charge-basis");
          } else {
            sweatalert.fire({
              title: res.msg,
              icon: "error",
              timer: 3000,
            });
          }
        })
        .catch((err) => {
          console.log("Error in creating charge basis", err);
        });
    },
  });

  useEffect(() => {
    if (formik.errors) {
      ScrollToViewFirstError(formik.errors);
    }
  }, [formik]);

  const fields = [
    {
      type: "text",
      name: "name",
      label: "Name of charge basis:",
      placeholder: "Enter Name of charge basis",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "select1",
      name: "basistypes_id",
      label: "Basis Type:",
      options: getBasisTypeData?.map((data) => ({ value: data.id, label: data.name })),
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "tags",
      label: "Tags:",
      placeholder: "Enter Tags",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "hidden",
      name: "hiding_tags",
      label: "Tags",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "togglebtn",
      name: "status",
      label: "Status:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "togglebtn",
      name: "input_required",
      label: "Input Required:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
  ];

  const handleChangeInputs = (index, type, name, e) => {
    let value = e.target.value;

    if (type == 1) {
      if (name == "unitValue" && isNaN(value)) return;
      validate(index, type, name, value);
      setUnitName((unitName) => ({
        ...unitName,
        [name == "unitValue" ? "value" : "name"]: value,
      }));
    }
    if (type == 2) {
      if (name == "value" && !/^\d*\.?\d*$/.test(value)) {
        e.target.value = "";
        return;
      } else {
      }
      validate(index, type, name, value);
      handleRangeChange(index, name, value);
    }
    if (type == 3) {
      if (name == "value" && !/^\d*\.?\d*$/.test(value)) {
        e.target.value = "";
        return;
      } else {
      }
      validate(index, type, name, value);
      handleFrequencyChange(index, name, value);
    }
    if (type == 4) {
      if (!/^\d*\.?\d*$/.test(value)) {
        e.target.value = "";
        return;
      } else {
      }
      // cp
      validate(index, type, name, value);
      setYesnoValue((prevState) =>
        prevState.map((item) =>
          item.name == name ? { ...item, value: value } : item
        )
      );
    }
  };

  const validate = (index, type, name, value) => {
    const newErrors = { ...errors };
    if (type == 1) {
      if (!value?.trim()) {
        newErrors.perUnit[name] = `${name == "unitValue"
            ? EMAPTY_PRICE_PER_UNIT_ERROR
            : EMPTY_NAME_OF_UNIT_ERROR
          }`;
      } else {
        delete newErrors.perUnit[name];
      }
      setErrors(newErrors);
    } else if (type === 2) {
      if (!value?.trim()) {
        newErrors.brandRange = newErrors.brandRange || [];
        newErrors.brandRange[index] = {
          ...newErrors.brandRange[index],
          [name]:
            name == "minValue"
              ? EMPTY_MIN_BAND_BASIS_ERROR
              : name == "maxValue"
                ? EMPTY_MAX_BAND_BASIS_ERROR
                : EMAPTY_BAND_VALUE_ERROR,
        };
      } else {
        if (newErrors.brandRange?.[index]) {
          delete newErrors.brandRange[index][name];
          if (Object.keys(newErrors.brandRange[index]).length === 0) {
            delete newErrors.brandRange[index];
          }
        }
      }

      setErrors(newErrors);
    }
    else if (type === 3) {
      if (!value?.trim()) {
        newErrors.frequency = newErrors.frequency || [];
        newErrors.frequency[index] = {
          ...newErrors.frequency[index],
          [name]:
            name == "name"
              ? EMPTY_FREQUENCY_NAME_ERROR
              : EMPTY_FREQUENCY_VALUE_ERROR,
        };
      } else {
        if (newErrors.frequency?.[index]) {
          delete newErrors.frequency[index][name];
          if (Object.keys(newErrors.frequency[index]).length === 0) {
            delete newErrors.frequency[index];
          }
        }
      }
      setErrors(newErrors);
    }
    else if (type === 4) {
      if (!value?.trim()) {
        newErrors.yesNo[name] = EMPTY_YESNO_ERROR;
      } else {
        delete newErrors.yesNo[name];
      }
      setErrors(newErrors);
    }
  };

  const handleClose = () => {
    navigate("/admin/pricing/all-charge-basis");
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/pricing/all-charge-basis">
              <i className="bx bx-arrow-back text-pink"></i>
            </Link>{" "}
            Charge Basis
          </h4>
        </div>
      </div>
      <div className="card form-card">
        <div className="card-header">
          <h5 className="card-title">Create Charge Basis</h5>
        </div>
        <div className="card-body px-0 px-lg-3">
          <ReusableForm
            fieldtype={fields.filter(
              (field) => !field.showWhen || field.showWhen(formik.values)
            )}
            formik={formik}
            btn_name="Save"
            closeBtn={handleClose}
            additional_field={
              formik.values.basistypes_id == "2" ||
                formik.values.basistypes_id == "1" ? (
                <div className="row  ps-0">
                  <div className="col-md-8">
                    <div className="row">
                      <label className="col-sm-3 me-2 col-form-label">
                        Name of Unit:
                      </label>
                      <div className="col-12 col-md-5 pe-md-0 ps-md-1">
                        <input
                          type="text"
                          className={`form-control ${errors?.perUnit?.unitName ? "is-invalid" : ""
                            }`}
                          placeholder="Enter Name of Unit"
                          name="unitName"
                          onChange={(e) =>
                            handleChangeInputs(0, 1, "unitName", e)
                          }
                          value={unitName.name || ""}
                        />
                        {errors?.perUnit?.unitName && (
                          <div className="error-text">
                            {errors.perUnit.unitName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <label className="col-sm-3 me-2 col-form-label">
                        Price per Unit:
                      </label>
                      <div className="col-12 col-md-5 pe-md-0 ps-md-1 ">
                        <div className="input-group">
                          
                          <span class="input-group-text">£</span>

                           
                          <input
                            type="text"
                            className={`form-control ${errors?.perUnit?.unitValue ? "is-invalid" : ""
                              }`}
                            placeholder="Enter Price per Unit"
                            name="unitValue"
                            onChange={(e) =>
                              handleChangeInputs(0, 1, "unitValue", e)
                            }
                            value={unitName.value || ""}
                          />
                         
                        </div>
                        {errors?.perUnit?.unitValue && (
                            <div className="error-text">
                              {errors.perUnit.unitValue}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : formik.values.basistypes_id == "3" ? (
                <div className="col-lg-8">
                  <div className="row">
                    <label className="col-lg-3 me-3 col-form-label">
                      Set Defaults Bands:
                    </label>
                    <div className="col-lg-8 ps-md-0">
                      {brandRangeArr.map((item, index) => (
                        <React.Fragment key={item.id}>
                          <div></div>

                          <div className="row mb-3">
                            <div className="col-lg-8">
                              <div className="d-flex">
                                <div>
                                  <input
                                    type="text"
                                    className={`form-control border `}
                                    placeholder="Min Value"
                                    name={`minValue_${index}`}
                                    onChange={(e) =>
                                      handleChangeInputs(index, 2, "minValue", e)
                                    }
                                  />
                                  {errors?.brandRange &&
                                    errors?.brandRange?.[index] && (
                                      <div className="error-text">
                                        {errors?.brandRange?.[index]?.minValue}
                                      </div>
                                    )}

                                </div>

                                <div>
                                  <input
                                    type="text"
                                    className={`form-control  ms-2`}
                                    placeholder="Max Value"
                                    name={`maxValue_${index}`}
                                    onChange={(e) =>
                                      handleChangeInputs(index, 2, "maxValue", e)
                                    }
                                  />
                                  {errors?.brandRange &&
                                    errors?.brandRange?.[index] && (
                                      <div className="error-text">
                                        {
                                          errors?.brandRange?.[index]?.maxValue}
                                      </div>
                                    )}

                                </div>


                              </div>

                            </div>
                            <div className="col-lg-4 ps-0">
                              <div className="d-flex">
                                <div>
                                  <div className="d-flex  justify-content-center">
                                    <div className="input-group">
                                      <span className="input-group-text border-right-0">£</span>
                                      <input
                                        type="text"
                                        name={`value_${index}`}
                                        className={`form-control w-75`}
                                        onChange={(e) =>
                                          handleChangeInputs(index, 2, "value", e)
                                        }
                                        value={brandRangeArr[index]?.value}
                                      />
                                    </div>
                                  </div>
                                  {errors?.brandRange &&
                                    errors?.brandRange?.[index] && (
                                      <div className="error-text">
                                        {errors?.brandRange?.[index]?.value}
                                      </div>
                                    )}
                                </div>
                                {brandRangeArr.length === 1 ? (
                                  ""
                                ) : (
                                  <button
                                    className="btn bg-dark rounded-0 px-1 ms-1"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      removeBasisRange(index);
                                    }}
                                  >
                                    <i className="bi bi-dash text-white"></i>
                                  </button>
                                )}
                              </div>

                            </div>
                          </div>
                          
                        </React.Fragment>
                      ))}
                      <button className="btn btn-pink rounded-0 mt-1" onClick={() => setBrandRangeArr([...brandRangeArr, { id: uuidv4(), name: "", value: '0.00' }])}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : formik.values.basistypes_id == "4" ? (
                <div className="row mb-4 mt-3">
                  {frequencyArr.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <div className="row ">
                        <div className="col-md-6">
                          <div className="row ">
                            <label
                              className="col-sm-4  me-3 col-form-label mb-3"

                            >
                              Frequency Name:
                            </label>
                            <div className="col-7 ps-md-0 ">
                              <input
                                type="text"
                                className="form-control"
                                name={`name_${index}`}
                                placeholder="Enter Frequency Name"
                                onChange={(e) =>
                                  handleChangeInputs(index, 3, "name", e)
                                }
                              />
                              {errors.frequency &&
                                errors.frequency[index] &&
                                errors.frequency[index].name && (
                                  <div className="error-text">
                                    {errors.frequency[index].name}
                                  </div>
                                )}
                            </div>


                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="row">
                            <label
                              className="col-sm-4 text-center col-form-label"

                            >
                              Value:
                            </label>
                            <div className="col-7">
                              <input
                                type="text"
                                className="form-control"
                                name={`value_${index}`}
                                placeholder="Enter Frequency Value"
                                onChange={(e) =>
                                  handleChangeInputs(index, 3, "value", e)
                                }
                              />
                              {errors.frequency &&
                                errors.frequency[index] &&
                                errors.frequency[index].value && (
                                  <div className="error-text">
                                    {errors.frequency[index].value}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-1">
                          {frequencyArr.length === 1 ? (
                            ""
                          ) : (
                            <button
                              className="btn bg-dark rounded-0 p-1"
                              onClick={(e) => {
                                e.preventDefault();
                                removeFrequency(index);
                              }}
                            >
                              <i className="bi bi-dash text-white p-2"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="row">
                    <div className="col-1 d-flex justify-content-end w-100">
                      <button className="btn btn-pink rounded-0 mt-1 me-4" onClick={() => setFrequencyArr([...frequencyArr, { id: uuidv4(), name: "", value: "" }])}>
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  </div>
                </div>
              ) : formik.values.basistypes_id == "5" ? (
                <div className="row  ps-0">
                  <div className="col-md-8">
                    <div className="row">
                      <label
                        className="col-sm-3 me-3 col-form-label"

                      > 
                        If Yes:
                      </label>
                      <div className="col-12 col-md-5 pe-md-0 ps-md-0">
                        <div className="input-group">
                          <span class="input-group-text  border-right-0">£</span>
                          <input
                            type="text"
                            placeholder="Enter Value"
                            className="form-control"
                            onChange={(e) =>
                              handleChangeInputs(0, 4, "Yes", e)
                            }
                            value={yesNoValue[0].value}
                          />
                        </div>
                        {
                          Object.keys(errors?.yesNo)?.length > 0 && (
                            <div className="error-text">{errors.yesNo.Yes}</div>
                          )
                        }
                      </div>
                    </div>
                    <div className="row mt-3">
                      <label
                        className="col-sm-3 me-3 col-form-label"

                      >
                        If No:
                      </label>
                      <div className="col-12 col-md-5 pe-md-0 ps-md-0">
                        <div className="input-group">
                          <span class="input-group-text border-right-0">£</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Value"
                            onChange={(e) =>
                              handleChangeInputs(0, 4, "No", e)
                            }
                            value={yesNoValue[1].value}
                          />
                        </div>
                        {
                          Object.keys(errors?.yesNo)?.length > 0 && (
                            <div className="error-text">{errors.yesNo.No}</div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default CreateChargebasis;

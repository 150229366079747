import React, { useState, useRef, useEffect, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import sweetalert2 from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReusableModal from "../../../ExtraComponents/ReusableModal";
import JoditEditor from 'jodit-react';

import {
  getTriggerEvents,
  getReplyTo,
  getTags,
  createEmailTemp,
} from "../../../ReduxStore/Slice/Templets/EmailTemplet";
import {
  EMPTY_TEMPLET_NAME_ERROR,
  EMPTY_TRIGGER_EVENT_ERROR,
} from "../../../Utils/Common_Error";

const CreateEmailTemplate = () => {

  const editor = useRef(null);
  const [editorReady, setEditorReady] = useState(false);
  const [content, setContent] = useState('');
  const quillRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [error, setError] = useState({});
  const [triggerEventsData, setTriggerEventsData] = useState([]);
  const [getTagsData, setTagsData] = useState({
    client: [],
    practice: [],
    user_profile: [],
  });
  const [ReplyToData, setReplyToData] = useState([]);
  const [formData, setFormData] = useState({
    templateName: "",
    description: "",
    triggerEvent: "",
    subjectLine: "",
    bccEmail: "",
    replyTo: "",
    body: "",
    attachments: [],
  });

  useEffect(() => {
    triggerEvents();
    ReplyTo();
    Tags();
  }, []);

  const triggerEvents = async () => {
    await dispatch(getTriggerEvents())
      .unwrap()
      .then((res) => {
        if (res.status) {
          setFormData({ ...formData, triggerEvent: res?.data[0]?.id });
          setTriggerEventsData(res.data);
        } else {
          setTriggerEventsData([]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const config = useMemo(() => ({
    readonly: false,
    placeholder: 'Start typing...',
    uploader: {
      url: '/upload',
      accept: 'image/*',
      method: 'POST',
      isRunOnce: true,
      onUpload: (files, insert) => {
        const formData = new FormData();
        formData.append('file', files[0]);
        fetch('/upload', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            const imageUrl = data.url;
            insert(imageUrl);
          })
          .catch((error) => {
            console.log('Image upload failed:', error);
          });
      },
    },
    events: {
      afterInit: (editorInstance) => {
        setEditorReady(true);
        console.log('Editor initialized');
        editor.current = editorInstance;
      },
    },
  }), []);

  const ReplyTo = async () => {
    await dispatch(getReplyTo())
      .unwrap()
      .then((res) => {
        if (res.status) {
          setReplyToData(res.data);
          setFormData({ ...formData, replyTo: res?.data[0]?.id });
        } else {
          setReplyToData([]);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  const Tags = async () => {
    await dispatch(getTags())
      .unwrap()
      .then((res) => {
        if (res.status) {
          const filterClient = res.data.filter((data) => data.type == "client");
          const filterPractice = res.data.filter(
            (data) => data.type == "practice"
          );
          const filterUserProfile = res.data.filter(
            (data) => data.type == "user_profile"
          );
          setTagsData({
            client: filterClient,
            practice: filterPractice,
            user_profile: filterUserProfile,
          });
        } else {
          setTagsData({ client: [], practice: [], user_profile: [] });
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  const handleSubmit = async () => {
    const req = { formData: formData };
    const isValid1 = validateAllFields();
    if (!isValid1) {
      return;
    }

    await dispatch(createEmailTemp(req))
      .unwrap()
      .then((res) => {
        if (res.status) {
          sweetalert2.fire({
            title: "Success",
            text: res.message,
            icon: "success",
            confirmButtonText: "OK",
            timer: 1500,
            timerProgressBar: true,
          });
          setTimeout(() => {
            navigate("/admin/template/all-email-template");
          }, 1500);
        } else {
          sweetalert2.fire({
            title: "Error",
            text: res.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAttachmentChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      attachments: [...formData.attachments, ...files],
    });
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = formData.attachments.filter(
      (_, i) => i !== index
    );
    setFormData({ ...formData, attachments: updatedAttachments });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validate(name, value);
  };

  const validateAllFields = () => {
    let isValid = true;
    for (const key in formData) {
      if (!validate(key, formData[key])) {
        isValid = false;
      }
    }
    return isValid;
  };

  const deleteErrorMsg = (name) => {
    setError((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name];
      return updatedErrors;
    });
  };

  const validate = (name, value) => {
    const newErrors = { ...error };
    if (!value) {
      switch (name) {
        case "templateName":
          newErrors.templateName = EMPTY_TEMPLET_NAME_ERROR;
          break;
        case "triggerEvent":
          newErrors.triggerEvent = EMPTY_TRIGGER_EVENT_ERROR;
        default:
          break;
      }
    } else {
      delete newErrors[name];
      deleteErrorMsg(name);
    }

    if (Object.keys(newErrors).length !== 0) {
      setError((prevErrors) => ({
        ...prevErrors,
        ...newErrors,
      }));
    }

    return Object.keys(newErrors).length === 0;
  };

  const insertTag = (tag) => {
    if (editorReady && editor.current) {
      const editorInstance = editor.current;
      if (editorInstance && editorInstance.selection) {
        editorInstance.selection.insertHTML(`{{${tag?.name?.toUpperCase()}}}\t\t`);
      }
    }
  };


  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/template/all-email-template">
              <i className="bx bx-arrow-back text-pink pe-1"></i>
            </Link>
            Create Email Template
          </h4>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body tempaltes">
              <div className=" needs-validation">
                <div className="row mb-4">
                  <div className="col-md-12 mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Template name:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="templateName"
                          onChange={handleInputChange}
                          value={formData.templateName}
                        />
                        {error.templateName && (
                          <div className="text-danger">
                            {error.templateName}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Description:
                      </label>
                      <div className="col-sm-9">
                        <textarea
                          className="form-control"
                          name="description"
                          onChange={handleInputChange}
                          value={formData.description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Trigger Event :
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-select"
                          name="triggerEvent"
                          onChange={handleInputChange}
                          value={formData.triggerEvent}
                        >
                          {triggerEventsData.map((data) => (
                            <option value={data.id}>{data.name}</option>
                          ))}
                        </select>
                        {error?.triggerEvent && (
                          <div className="text-danger">
                            {error.triggerEvent}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Subject line:
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          name="subjectLine"
                          onChange={handleInputChange}
                          value={formData.subjectLine}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        BCC Email:
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          name="bccEmail"
                          onChange={handleInputChange}
                          value={formData.bccEmail}
                        />
                      </div>
                      <div className="col-sm-1 ps-0">
                        <span>
                          <i className="bx bx-info-circle fs-4 mt-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  mb-3">
                    <div className="row ">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 col-form-label"
                      >
                        Reply to:
                      </label>
                      <div className="col-sm-6">
                        <select
                          className="form-select"
                          name="replyTo"
                          onChange={handleInputChange}
                          value={formData.replyTo}
                        >
                          {ReplyToData.map((data) => (
                            <option value={data.id}>{data.email}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-1 ps-0">
                        <span>
                          <i className="bx bx-info-circle fs-4 mt-1"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <label className="col-sm-3 col-form-label">Body:</label>
                      <div className="col-sm-9">
                        <JoditEditor
                          ref={editor}
                          config={config}
                          tabIndex={1}
                          onBlur={(newContent) => setContent(newContent)}
                          name="body"
                          onChange={(value) => {
                            setFormData({ ...formData, body: value });
                            validate("body", value);
                          }}
                          value={formData.body}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        <i className="bi bi-paperclip me-2"></i>
                        Add Attachment
                      </button>
                      <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleAttachmentChange}
                        className="d-none"
                      />
                      <div className="attachment-list mt-3">
                        {formData.attachments.map((file, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center gap-2"
                          >
                            <i className="bi bi-file-earmark"></i>
                            <span>{file.name}</span>
                            <button
                              type="button"
                              className="btn btn-sm btn-danger d-flex align-items-center"
                              onClick={() => handleRemoveAttachment(index)}
                            >
                              <i className="bi bi-x"></i>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-auto text-end">
                  <button
                    className="btn btn-outline-secondary rounded"
                    onClick={() =>
                      navigate("/admin/template/all-email-template")
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-pink rounded ms-2"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="quill-editor-full"></div>
        </div>
        <div className="col-md-4">
          <div className="card pt-3 px-2">
            <h5 className="text-gray">
              Tag Provision <i class="bx bx-info-circle text-pink"></i>
            </h5>
            <ul
              class="nav nav-tabs nav-tabs-bordered d-flex"
              id="borderedTabJustified"
              role="tablist"
            >
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100 active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-home"
                  type="button"
                  role="tab"
                  aria-controls="Client"
                  aria-selected="true"
                >
                  Client
                </button>
              </li>
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-profile"
                  type="button"
                  role="tab"
                  aria-controls="Practice"
                  aria-selected="false"
                >
                  Practice
                </button>
              </li>
              <li class="nav-item flex-fill" role="presentation">
                <button
                  class="nav-link w-100"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bordered-justified-contact"
                  type="button"
                  role="tab"
                  aria-controls="User-Profile"
                  aria-selected="false"
                >
                  User Profile
                </button>
              </li>
            </ul>
            <div class="tab-content p-3" id="borderedTabJustifiedContent">
              <div
                class="tab-pane fade show active"
                id="bordered-justified-home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {/* Tag buttons */}
                <div className="tag-buttons">
                  {getTagsData.client.map((tag) => (
                    <button
                      key={tag.id}
                      onClick={() => insertTag(tag)}
                      className="tag-btn"
                    >
                      {"+ " + tag?.name?.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="bordered-justified-profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="tag-buttons">
                  {getTagsData.practice.map((tag) => (
                    <button
                      key={tag.id}
                      onClick={() => insertTag(tag)}
                      className="tag-btn"
                    >
                      {"+ " + tag?.name?.toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="bordered-justified-contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                {getTagsData.user_profile.map((tag) => (
                  <button
                    key={tag.id}
                    onClick={() => insertTag(tag)}
                    className="tag-btn"
                  >
                    {"+ " + tag?.name?.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEmailTemplate;

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Service from "./Service";
import History from "./History";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { allPackageService, } from "../../../ReduxStore/Slice/Pricing/ChargeBasisSlice";
import { useFormik } from "formik";
import { getSinglePackage, updatePackage } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import { getServiceApplicable } from "../../../ReduxStore/Slice/Pricing/ServiceSlice";
import { useNavigate } from "react-router-dom";
import ReusableModal from '../../../ExtraComponents/ReusableModal';
import { useLocation } from "react-router-dom";
import sweatalert from "sweetalert2";
import {
  EMPTY_PACKAGE_NAME_ERROR, EMPTY_APPLICABLE_TO_ERROR, EMPTY_TOTAL_COST_OF_SERVICES_ERROR, EMPTY_DEFAULT_PACKAGE_PRICE_ERROR,
  EMPTY_DEFAULT_DISCOUNT_ERROR, EMPTY_THRESHOLD_PRICE_ERROR, EMPTY_MINIMUM_PRICE_ERROR, EMPTY_THRESHOLD_DISCOUNT_ERROR, EMPTY_MAXIMUM_DISCOUNT_ERROR
} from '../../../Utils/Common_Error';


const ViewPackage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CurrentTab = parseInt(sessionStorage.getItem("ViewServiceTab"));
  const location = useLocation();
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState(false);
  const [showDefultValueModal, setShowDefultValueModal] = useState(false);
  const [checkedList, setcheckedList] = useState([]);
  const [serviceList, setserviceList] = useState([]);
  const [PriceCalculation, setPriceCalculation] = useState([]);
  const [serviceRowData, setServiceRowData] = useState({});
  const [currantTab, setCurrantTab] = useState(CurrentTab || 1);
  const [serviceApplicableData, setServiceApplicableData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [addedService, setaddedService] = useState([]);
  const handleCloseDefultValueModal = () => setShowDefultValueModal(false);


  useEffect(() => {
    ServiceApplicable();
  }, []);

  useEffect(() => {
    getAllService();
  }, [inputValue]);


  useEffect(() => {
    if (serviceApplicableData.length > 0) {
      getPackageById();
    }
  }, [serviceApplicableData]);

  const ServiceApplicable = async () => {
    const req = { token: token };
    try {
      const res = await dispatch(getServiceApplicable(req)).unwrap();
      if (res.status) {
        setServiceApplicableData(res.data);
      } else {
        setServiceApplicableData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPackageById = async () => {
    const req = { id: location?.state?.row?.id };
    try {
      const response = await dispatch(getSinglePackage(req)).unwrap();
      if (response.status) {
        const packageDetails = response.data?.[0] || {};

        const applicableTo = packageDetails.PackageApplicables?.map((id) => {
          const service = serviceApplicableData.find((item) => item.id === id);
          return service ? { label: service.name, value: service.id } : null;
        }).filter(Boolean);


        formik.setFieldValue("packagename", packageDetails.name || "");
        formik.setFieldValue("packagestatus", packageDetails.status || "");
        formik.setFieldValue("applicabelto", applicableTo || []);
        formik.setFieldValue("discrption", packageDetails.description || "");
        formik.setFieldValue("defaultprice", packageDetails.default_price.toFixed(2) || "");
        formik.setFieldValue("defaultdiscount", packageDetails.default_discount || "");
        formik.setFieldValue("Thersholdprice", packageDetails.threshold_price.toFixed(2) || "");
        formik.setFieldValue("minimunprice", packageDetails.min_price.toFixed(2) || "");
        formik.setFieldValue("thersholddiscount", packageDetails.threshold_discount || "");
        formik.setFieldValue("maxdiscount", packageDetails.max_discount || "");
        formik.setFieldValue("totalcostofservices", packageDetails.cost_service_price.toFixed(2) || "");
      } else {

      }
    } catch (error) {
      console.log("Error in ViewPackage", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      packagename: "",
      packagestatus: "",
      applicabelto: [],
      Packagetype: "",
      discrption: "",
      defaultprice: "",
      defaultdiscount: "",
      Thersholdprice: "",
      minimunprice: "",
      thersholddiscount: "",
      maxdiscount: "",
      totalcostofservices: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.packagename) {
        errors.packagename = EMPTY_PACKAGE_NAME_ERROR;
      }
      if (!values.defaultprice) {
        errors.defaultprice = EMPTY_DEFAULT_PACKAGE_PRICE_ERROR;
      }
      if (!values.minimunprice) {
        errors.minimunprice = EMPTY_MINIMUM_PRICE_ERROR;
      }
      // if (!values.Thersholdprice) {
      //   errors.Thersholdprice = EMPTY_THRESHOLD_PRICE_ERROR;
      // }
      return errors;
    },

    onSubmit: async (values) => {
      const req = {
        id: location?.state?.row?.id,
        name: values.packagename,
        status: values.packagestatus,
        description: values.discrption,
        default_price: values.defaultprice,
        threshold_price: values.Thersholdprice,
        min_price: values.minimunprice,
        max_discount: values.maxdiscount,
        threshold_discount: values.thersholddiscount,
        default_discount: values.defaultdiscount,
        cost_service_price: values.totalcostofservices,
        applicable_to: values.applicabelto.map((item) => item.value),
        packageservices: addedService.map((service) => {
          const serviceCalculation = PriceCalculation.find((item) => item.id === service.id);
          const chargetypes = serviceCalculation
            ? Object.keys(serviceCalculation)
              .filter((key) => serviceCalculation[key]?.id !== undefined)
              .map((key) => ({
                id: serviceCalculation[key].id,
                value: serviceCalculation[key].value,
                operator: serviceCalculation[key].operator,
              }))
            : [];

          return {
            service_id: service.id,
            price: service.price,
            chargetypes,
          };
        })
      };
      await dispatch(updatePackage(req)).unwrap()
        .then((res) => {
          if (res.status) {
            sweatalert.fire({
              title: "Success",
              text: res.message,
              icon: "success",
              confirmButtonText: "OK",
              timer: 2000,
              timerProgressBar: true,
            });
            navigate("/admin/pricing/all-packages");
          } else {
            sweatalert.fire({
              title: "Error",
              text: res.message,
              icon: "error",
              confirmButtonText: "OK",
              timer: 2000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });


  const fields = [
    {
      name: "packagename",
      label: "Package Name",
      type: "text",
      label_size: 5,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "select1",
      name: "packagestatus",
      label: "Package Status",
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" },
      ],
      label_size: 5,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "multiselect",
      name: "applicabelto",
      label: "Applicable To",
      options: serviceApplicableData.map((data) => ({
        label: data.name,
        value: data.id,
      })),
      label_size: 5,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "discrption",
      label: "Description",
      label_size: 2,
      col_size: 12,
      disable: false,
      child_col_size: 9,
      margin: "50px",
    },
    {
      type: "text",
      name: "totalcostofservices",
      label: "Total Cost of Services",
      label_size: 5,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
    {
      type: "text3",
      name: "defaultprice",
      label: "Default Price",
      label_size: 5,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text3",
      name: "minimunprice",
      label: "Minimum Price",
      label_size: 5,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text3",
      name: "Thersholdprice",
      label: "Threshold Price",
      label_size: 5,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text2",
      name: "defaultdiscount",
      label: "Default Discount",
      label_size: 5,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
    {
      type: "text2",
      name: "maxdiscount",
      label: "Maximum Discount",
      label_size: 5,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
    {
      type: "text2",
      name: "thersholddiscount",
      label: "Threshold Discount",
      label_size: 5,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
  ];

  const tabs = [
    { value: 1, name: "Information" },
    { value: 2, name: "Services" },
    { value: 3, name: "History" },
  ];

  const getAllService = async () => {
    const req = { package_id: location?.state?.row?.id, applicable_ids: formik.values.applicabelto.map((item) => item.value) }
    await dispatch(allPackageService(req)).unwrap()
      .then((res) => {
        if (res.status) {
          const filteredData = res?.data?.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
          setserviceList(inputValue ? filteredData : res.data)
        } else {
          setserviceList([])
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const SetvalueModal = (service) => {
    setShowDefultValueModal(true)
    const updatedPriceCalculation = service.ServiceChargeBases.reduce((acc, data) => {
      const existingItem = PriceCalculation.find((item) => item.id == service.id);
      if (existingItem) {

      } else {
        acc.push({
          id: service.id,
          [data.ChargeBasis.name]: {
            id: data.ServiceChargeBasisValues[0].chargetype_rule_id,
            operator: data.operator,
            value: data.ServiceChargeBasisValues[0].value,
          },
        });
      }
      return acc;
    }, [...PriceCalculation]);
    const result = Object.values(
      updatedPriceCalculation.reduce((acc, item) => {
        const { id, ...rest } = item;
        if (!acc[id]) {
          acc[id] = { id, ...rest };
        } else {
          Object.assign(acc[id], rest);
        }
        return acc;
      }, {})
    );

    console.log(result);
    setPriceCalculation(result);
  }

  const handleChangeDefultValue = (e, data, ServiceId) => {
    setPriceCalculation((prev) => {
      const existingIndex = prev.findIndex((item) => item.id === ServiceId.id);
      if (existingIndex !== -1) {
        return prev.map((item, index) =>
          index === existingIndex
            ? {
              ...item,
              [data?.ChargeBasis?.name]: {
                id: e.target.value,
                operator: data?.operator,
                value:
                  data?.ServiceChargeBasisValues.find(
                    (charge) => charge.chargetype_rule_id == e.target.value
                  )?.value || "",
              },
            }
            : item
        );
      } else {

        return [

          ...prev,
          {
            id: ServiceId.id,
            [data?.ChargeBasis?.name]: {
              id: e.target.value,
              operator: data?.operator,
              value:
                data?.ServiceChargeBasisValues.find(
                  (charge) => charge.chargetype_rule_id == e.target.value
                )?.value || "",
            },
          },
        ];
      }
    });

  }

  const ShowServiceData = (data, ServiceId) => {
    const MatchedData = PriceCalculation.find((item) => item.id == ServiceId.id)
    if (MatchedData) {
      return MatchedData[data?.ChargeBasis?.name]?.id;
    }
    return "";
  }

  const handleSetDefultPrice = () => {
    PriceCalculation.forEach((item) => {
      let totalSum = 0;
      Object.keys(item).forEach((key) => {
        if (key !== "id" && item[key].value && item[key].operator) {
          const value = parseFloat(item[key].value);
          switch (item[key].operator) {
            case "+":
              totalSum += value;
              break;
            case "-":
              totalSum -= value;
              break;
            case "×":
              totalSum *= value;
              break;
            case "÷":
              totalSum = value !== 0 ? totalSum / value : totalSum;
              break;
            default:
              console.error(`Unknown operator: ${item[key].operator}`);
          }
        }
      });


      console.log("totalSum", totalSum);
      const MatchedData = checkedList.find((item1) => item1.id == item.id);
      if (MatchedData) {
        MatchedData.price = totalSum;
      }
    });

    setcheckedList([...checkedList]);
    setShowDefultValueModal(false);
  };

  const handleAddService = () => {
    const invalidObject = checkedList.find((obj) => obj.price === null);
    const isValid = invalidObject === undefined;

    if (!isValid) {
      sweatalert.fire({
        title: "Warning",
        text: "Please enter default value for all services",
        icon: "warning",
        confirmButtonText: "OK",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }


    const totalCost = checkedList.reduce((acc, item) => {
      return acc + (item.price ? Number(item.price) : 0);
    }, 0)

    let newTotalPrice = parseFloat(formik.values.totalcostofservices) + parseFloat(totalCost);
    let defaultdiscount = formik.values.defaultdiscount;
    let defaultPrice = newTotalPrice - (newTotalPrice * defaultdiscount / 100)
    let maxdiscount = formik.values.maxdiscount;
    let minimunprice = newTotalPrice - (newTotalPrice * maxdiscount / 100)
    let thersholddiscount = formik.values.thersholddiscount;
    let Thersholdprice = newTotalPrice - (newTotalPrice * thersholddiscount / 100)


    console.log("newTotalPrice", newTotalPrice);


    // let defaultPrice = formik.values.defaultprice;
    // let minimunprice = formik.values.minimunprice;
    // let Thersholdprice = formik.values.Thersholdprice;
    // let Defaultdiscount = ((newTotalPrice - defaultPrice) / newTotalPrice) * 100;
    // let ThersholdDiscount = ((newTotalPrice - Thersholdprice) / newTotalPrice) * 100;
    // let maxDiscount = ((newTotalPrice - minimunprice) / newTotalPrice) * 100;

    // formik.setFieldValue("defaultdiscount", Defaultdiscount);
    // formik.setFieldValue("thersholddiscount", ThersholdDiscount);
    // formik.setFieldValue("maxdiscount", maxDiscount);
    formik.setFieldValue("Thersholdprice", Thersholdprice.toFixed(2));
    formik.setFieldValue("minimunprice", minimunprice.toFixed(2));
    formik.setFieldValue("defaultprice", defaultPrice.toFixed(2));
    formik.setFieldValue("totalcostofservices", newTotalPrice);
    setaddedService([...addedService, ...checkedList]);
    setcheckedList([]);
    setShowModal(false);
  }

  const handleCloseModal = () => {
    setcheckedList([]);
    setPriceCalculation([]);
    setShowModal(false);
  }


  // useEffect(() => {
  //   formik.setFieldValue("totalcostofservices", formik.values.totalcostofservices);
  //  // formik.setFieldValue("defaultprice", Math.min(formik.values.defaultprice, formik.values.totalcostofservices));
  //   //formik.setFieldValue("minimunprice", Math.min(formik.values.minimunprice, Math.min(formik.values.defaultprice, formik.values.totalcostofservices)));
  //   //formik.setFieldValue("Thersholdprice", Math.min(formik.values.Thersholdprice, formik.values.totalcostofservices));
  // }, [addedService, formik.values.totalcostofservices, formik.values.defaultprice, formik.values.minimunprice]);

  useEffect(() => {
    formik.setFieldValue("totalcostofservices", formik.values.totalcostofservices);
    // formik.setFieldValue("defaultprice", Math.min(formik.values.defaultprice, formik.values.totalcostofservices));
    //formik.setFieldValue("minimunprice", Math.min(formik.values.minimunprice, Math.min(formik.values.defaultprice, formik.values.totalcostofservices)));
    //formik.setFieldValue("Thersholdprice", Math.min(formik.values.Thersholdprice, formik.values.totalcostofservices));
  }, [addedService]);

  useEffect(() => {

    if (formik.values.defaultprice && formik.values.totalcostofservices) {
      let temp = formik.values.totalcostofservices - formik.values.defaultprice;
      let getPercentage = (temp / formik.values.totalcostofservices) * 100;
      formik.setFieldValue("defaultdiscount", getPercentage?.toFixed(2));
    }

    if (formik.values.defaultprice && formik.values.minimunprice) {
      let temp = formik.values.totalcostofservices - formik.values.minimunprice;
      let getPercentage = (temp / formik.values.totalcostofservices) * 100;
      formik.setFieldValue("maxdiscount", getPercentage?.toFixed(2));
    }

    if (formik.values.Thersholdprice && formik.values.totalcostofservices) {
      let temp = formik.values.totalcostofservices - formik.values.Thersholdprice;
      let getPercentage = (temp / formik.values.totalcostofservices) * 100;
      formik.setFieldValue("thersholddiscount", getPercentage?.toFixed(2));
    }
  }, [formik.values.defaultprice, formik.values.minimunprice, formik.values.Thersholdprice, formik.values.totalcostofservices]);


  return (
    <div className="view-package">
      <>
        <div className="row mb-3">
          <div className="col">
            <h4 className="page-title">
              <button className="btn p-0 fs-5" onClick={() => { window.history.back(); sessionStorage.removeItem("ViewServiceTab") }}>
                <i className="bx bx-arrow-back text-pink"></i>
              </button> Packages
              <span>
                <i className=" ri-arrow-right-s-line"></i> {formik.values.packagename || ""}
              </span>
            </h4>
          </div>
          <div className="col-auto align-self-center">
            {currantTab == 1 && (
              <button className="btn btn-pink rounded ms-2" onClick={() => { setShowModal(true); getAllService(); setaddedService([]); setPriceCalculation([]); getPackageById() }} >Add New Service</button>
            )}
          </div>
        </div>

        <ul className="nav nav-tabs nav-tabs-bordered"  >
          {tabs.map((tab) => (
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${currantTab == tab.value ? "active" : ""}`}
                id={`${tab.name}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#bordered-${tab.name}`}
                type="button"
                role="tab"
                aria-controls="information"
                aria-selected="true"
                onClick={() => {
                  setCurrantTab(tab.value)
                  sessionStorage.setItem("ViewServiceTab", tab.value)
                }}
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content pt-4" id="borderedTabContent">
          <div className={`tab-pane fade ${currantTab == 1 ? 'show active' : ""}`}  >

            <div className="needs-validation">
              <ReusableForm
                fieldtype={fields.filter((field) => !field.showWhen || field.showWhen(formik.values))}
                formik={formik}
                btn_name="Save"
                closeBtn={() => window.history.back()}
                closeBtn1={false}
              />
              <ReusableModal
                show={showModal}
                modalsize={"xl"}
                onClose={handleCloseModal}
                title={"Add Service to package"}
                body={
                  <div>
                    <div className="mb-3 row">
                      <input type="text"
                        placeholder="Search Service"
                        style={{ borderRadius: "5px", padding: "7px" }}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card ">
                          <div className="card-header">
                            <h5 className="card-title ">Services</h5>
                          </div>
                          <div className="card-body">
                            <div className="service-table-container">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Service Name</th>
                                    <th>Service Type</th>
                                    <th>Values</th>
                                    <th>Default Price</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkedList?.map((service) => (
                                    <tr key={service?.id}>
                                      <td>
                                        <div className="d-flex">
                                          <div className="form-check me-3">
                                            <input
                                              type="checkbox"
                                              defaultChecked
                                              onClick={
                                                () => {
                                                  if (checkedList?.includes(service)) {
                                                    setcheckedList(checkedList?.filter((item) => item?.id != service?.id));
                                                    getAllService();
                                                  } else {
                                                    setcheckedList([...checkedList, service]);
                                                    getAllService();
                                                  }
                                                }
                                              }
                                              className="form-check-input">
                                            </input>
                                          </div>
                                          {service?.name}
                                        </div>
                                      </td>
                                      <td>{service?.price_type == "1" ? "Fixed" : "Variable"}</td>
                                      <td>{service?.price_type == "2" ? <div style={{ cursor: "pointer" }}>
                                        <a onClick={() => { SetvalueModal(service); setServiceRowData(service) }}> Enter Default Value</a>
                                      </div> : "-"}</td>
                                      <td>{service?.price ? Number(service?.price)?.toFixed(2) : "-"}</td>
                                    </tr>
                                  ))}

                                  {serviceList?.map((service) => ((
                                    checkedList.some((item) => item.id == service.id) ? null :
                                      <tr key={service?.id}>
                                        <td>
                                          <div className="d-flex">
                                            <div className="form-check me-3">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                onClick={() => {
                                                  if (checkedList?.includes(service)) {
                                                    setcheckedList(checkedList?.filter((item) => item?.id != service?.id));

                                                  } else {
                                                    setcheckedList([...checkedList, service]);
                                                  }
                                                }}
                                              >
                                              </input>
                                            </div>
                                            {service?.name}
                                          </div>
                                        </td>
                                        <td>{service?.price_type == "1" ? "Fixed" : "Variable"}</td>
                                        <td>{service?.price_type == "2" ? <div>
                                          <a> Enter Default Value</a>
                                        </div> : "-"}</td>
                                        <td>{service?.price ? service?.price : "-"}</td>
                                      </tr>
                                  )
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                footer={
                  <>
                    <button className="btn btn-outline-secondary" onClick={handleCloseModal}>
                      Cancel
                    </button>
                    <button className="btn btn-pink" onClick={handleAddService} >
                      Add
                    </button>
                  </>
                }
              />

              <ReusableModal
                show={showDefultValueModal}
                onClose={handleCloseDefultValueModal}
                title={"Enter Default Value"}
                body={<div>
                  <div className="mb-3 row">
                    {serviceRowData?.ServiceChargeBases?.map((data) => (
                      <div className="col-lg-12 row mb-2">
                        <label className="col-lg-4 col-form-label" for="name">
                          {data?.ChargeBasis?.name}:
                        </label>
                        <div className="col-lg-8">
                          <select className="form-select"
                            value={ShowServiceData(data, serviceRowData)}
                            onChange={(e) => handleChangeDefultValue(e, data, serviceRowData)}
                          >
                            {data?.ServiceChargeBasisValues?.map((value) => (
                              <option value={value?.ChargeType?.id}>{value?.ChargeType?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>}
                footer={
                  <>
                    <button className="btn btn-outline-secondary" onClick={handleCloseDefultValueModal}>
                      Cancel
                    </button>
                    <button className="btn btn-pink"
                      onClick={handleSetDefultPrice}
                    >
                      Apply
                    </button>
                  </>
                }
              />
            </div>
          </div>
          <div className={`tab-pane fade ${currantTab == 2 ? 'show active' : ""}`}>
            <Service />
          </div>
          <div className={`tab-pane fade ${currantTab == 3 ? 'show active' : ""}`} >
            <History />
          </div>
        </div>
        {/* End Bordered Tabs */}
      </>
    </div>
  );
};

export default ViewPackage;

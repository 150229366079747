import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    get_Trigger_Events,
    get_ReplyTo,
    get_Tags,
    create_Email_Temp,
    all_Email_Templet,
    delete_Email_Templet,
    get_Single_Templet,
    update_Email_Temp
} from '../../../Service/Templets/EmailTempletService'
const UserId = JSON.parse(localStorage.getItem("agent_id"));

export const createEmailTemp = createAsyncThunk("email/create_email_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Email_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const updateEmailTemp = createAsyncThunk("email/update_email_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await update_Email_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});
export const getTriggerEvents = createAsyncThunk("trriger_event", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Trigger_Events(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getReplyTo = createAsyncThunk("replyto", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_ReplyTo(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getTags = createAsyncThunk("gettemplatetags", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Tags(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const allEmailTemplet = createAsyncThunk("email/template_list", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await all_Email_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});
export const deleteEmailTemplet = createAsyncThunk("email/delete", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await delete_Email_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getSingleTemplet = createAsyncThunk("email/get_template_by_id", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Single_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});



const ChangeBasisSlice = createSlice({
    name: "ChangeBasisSlice",
    initialState: {
        isLoading: false,
        isError: false,
        gettriggerevent: [],
        replyto : [],
        gettags : [],
        createemailtemp: [],
        allemailtemplet: [],
        deleteemailtemplet : [],
        gettempletbyid : []
       
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTriggerEvents.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTriggerEvents.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettriggerevent = action.payload;
            })
            .addCase(getTriggerEvents.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getReplyTo.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getReplyTo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.replyto = action.payload;
            })
            .addCase(getReplyTo.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getTags.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettags = action.payload;
            })
            .addCase(getTags.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(createEmailTemp.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createEmailTemp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createemailtemp = action.payload;
            })
            .addCase(createEmailTemp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(allEmailTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(allEmailTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allemailtemplet = action.payload;
            })
            .addCase(allEmailTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(deleteEmailTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(deleteEmailTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deleteemailtemplet = action.payload;
            })
            .addCase(deleteEmailTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getSingleTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getSingleTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettempletbyid = action.payload;
            })
            .addCase(getSingleTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })



        
        
    },

});

export default ChangeBasisSlice;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    create_Quote,
} from '../../../Service/Quote/QuoteService'

const UserId = JSON.parse(localStorage.getItem("agent_id"));

export const createQuote = createAsyncThunk("create_quote", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Quote(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});


const QuoteSlice = createSlice({
    name: "QuoteSlice",
    initialState: {
        isLoading: false,
        isError: false,
        createquote: [],

    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createQuote.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createQuote.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createquote = action.payload;
            })
            .addCase(createQuote.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
    },

});

export default QuoteSlice;

import React, { useState, useEffect } from 'react';
import { viewPackage,deletePackage } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable1';
import { useNavigate } from 'react-router-dom';
import { PenLine, Trash2 } from 'lucide-react';
import sweatalert from "sweetalert2";


const Service = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getPackageData, setPackageData] = useState([]);

  useEffect(() => {
    getAllPackage();;
  }, []);

  const getAllPackage = async () => {
    await dispatch(viewPackage()).unwrap()
      .then((response) => {
        if (response.status) {
          setPackageData(response.data);
        } else {
          setPackageData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const deletePackageRow = async (row) => {
   sweatalert.fire({
           title: "Are you sure?",
           text: "You want to delete this package! ",
           icon: "warning",
           showCancelButton: true,
           confirmButtonText: "Yes, change it!",
           cancelButtonText: "No, cancel",
         }).then(async (result) => {
           if (result.isConfirmed) {
             const req = {  id : row.id };
             await dispatch(deletePackage(req)).unwrap()
             .then((response) => {
               if (response.status) {
                 sweatalert.fire({
                   title : "Success",
                   text : "Package Deleted Successfully",
                   icon : "success",
                   timer : 1500,
                   timerProgressBar : true
                  }
                 );
                 getAllPackage()
               }
             })
             .catch((error) => {
              console.error("Error deleting package:", error);
             });
           } else if (result.dismiss === sweatalert.DismissReason.cancel) {
             return
           }
         });

  }
  
  const columns = [
    {
      name: "Service Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => parseInt(row.status) === 1 ? "Active" : "Inactive",
      sortable: true,
    },
    {name: (
      <div className='w-100'>
        Applicable<br /> To
      </div>
    ),
    selector: (row) => 
      row.PackageApplicables
        .map((item) => <div className='applicable-data'>{item.Applicable.name} <i className='bx bxs-x-circle'></i></div>)
        ,
          sortable: true,
          width: "15%",

  },
    {
      name: "total Cost of Service",
      selector: (row) => row.cost_service_price,
      sortable: true,
    },
    {
      name: "Default Price",
      selector: (row) => row.default_price,
      sortable: true,
    },
    {
      name: "Threshold Price",
      selector: (row) => row.threshold_price,
      sortable: true,
    },
    {
      name: "Final Package Price",
      selector: (row) => row.final_package_price,
      sortable: true,
    },




    {
      name: "Action",
      selector: (row) => <>
        <PenLine
          onClick={() =>
            navigate('/admin/pricing/view-package', { state: { row: row }, })
          }
        />
        <Trash2
          onClick={() => deletePackageRow(row)}
        />
      </>,
      sortable: true,
    },
  ];

  return (
    <div>

      <section className="section dashboard">
        <div className="row">
            <h4 className="page-title">
            <button className="btn p-0 fs-5" onClick={() => window.history.back()}>
            <i className="bx bx-arrow-back text-pink pe-1"></i>
              </button>
                      
                     All Packages
                    </h4>
        
          < div className='  package-table'>
            <Datatable
              columns={columns}
              data={getPackageData}
              filter={false}
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default Service;

import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const Example = ({ placeholder }) => {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [editorReady, setEditorReady] = useState(false); 

    const config = useMemo(() => ({
        readonly: false,
        placeholder: placeholder || 'Start typing...',
        uploader: {
            url: '/upload',  
            accept: 'image/*',  
            method: 'POST',
            isRunOnce: true,
            onUpload: (files, insert) => {
                const formData = new FormData();
                formData.append('file', files[0]);
                fetch('/upload', {
                    method: 'POST',
                    body: formData,
                })
                .then((response) => response.json())
                .then((data) => {
                    const imageUrl = data.url; 
                    insert(imageUrl);
                })
                .catch((error) => {
                    console.log('Image upload failed:', error);
                });
            },
        },
        events: {
            afterInit: (editorInstance) => {
                setEditorReady(true);
                console.log('Editor initialized');
                editor.current = editorInstance; 
            },
        },
    }), [placeholder]);
 
    const insertTag = (tag) => {
        if (editorReady && editor.current) {
            const editorInstance = editor.current;
            if (editorInstance && editorInstance.selection) {
                editorInstance.selection.insertHTML(tag);
            }
        }
    };

    return (
        <div> 
            <button onClick={() => insertTag('<strong>Bold Text</strong>')}>Insert Tag</button>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
            />
        </div>
    );
};

export default Example;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Get_Basis_Type,
  create_ChargeBasis,
  get_Charge_Basis,
  get_Charge_Basis_byId,
  delete_ChargeBasis,
  update_Status,
  update_Change_Basis,
  All_Services_List,
  All_PackageServices,
  get_Charge_Basis_All,
  Delete_Service

} from '../../../Service/Pricing/ChargeBasisService'
const UserId = JSON.parse(localStorage.getItem("details"))?.id;

export const getBasisType = createAsyncThunk("basistypes", async (data) => {
  try {

    const updatedData = { ...data, agent_id: UserId }
    const res = await Get_Basis_Type(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const createChargeBasis = createAsyncThunk("createchargebasis", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await create_ChargeBasis(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const getChargeBasis = createAsyncThunk("service/getchargebasis", async (data) => {
  try {

    const updatedData = { ...data, agent_id: UserId }
    const res = await get_Charge_Basis(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const getChargeBasisAll = createAsyncThunk("service/getChargeBasisAll", async (data) => {
  try {

    const updatedData = { ...data, agent_id: UserId }
    const res = await get_Charge_Basis_All(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});



export const getChargeBasisbyId = createAsyncThunk("getchargebasisdetails", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await get_Charge_Basis_byId(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const deleteChargeBasis = createAsyncThunk("deletechargebasis", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await delete_ChargeBasis(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const updateStatus = createAsyncThunk("statusupdate", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await update_Status(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const updateChargeBasis = createAsyncThunk("updatechargebasis", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await update_Change_Basis(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const allServiceList = createAsyncThunk("servicelist", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await All_Services_List(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});

export const deleteService = createAsyncThunk("deleteService", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await Delete_Service(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});


export const allPackageService = createAsyncThunk("package/editPackageGetService", async (data) => {
  try {
    const updatedData = { ...data, agent_id: UserId }
    const res = await All_PackageServices(updatedData);
    return await res;
  } catch (err) {
    return err;
  }
});



const ChangeBasisSlice = createSlice({
  name: "ChangeBasisSlice",
  initialState: {
    isLoading: false,
    isError: false,
    getbasistype: [],
    createchargebasis: [],
    getchangebasis: [],
    getchangebasisbyid: [],
    deletechargebasis: [],
    updatestatus: [],
    updatechargebasis: [],
    allservicelist: [],
    allpackageservice: []
  },


  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBasisType.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBasisType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getbasistype = action.payload;
      })
      .addCase(getBasisType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(createChargeBasis.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createChargeBasis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createchargebasis = action.payload;
      })
      .addCase(createChargeBasis.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getChargeBasis.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChargeBasis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getchangebasis = action.payload;
      })
      .addCase(getChargeBasis.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getChargeBasisbyId.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getChargeBasisbyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getchangebasisbyid = action.payload;
      })
      .addCase(getChargeBasisbyId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
      .addCase(deleteChargeBasis.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteChargeBasis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletechargebasis = action.payload;
      })
      .addCase(deleteChargeBasis.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatestatus = action.payload;
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(updateChargeBasis.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateChargeBasis.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updatechargebasis = action.payload;
      })
      .addCase(updateChargeBasis.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
      .addCase(allServiceList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(allServiceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allservicelist = action.payload;
      })
      .addCase(allServiceList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
      .addCase(allPackageService.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(allPackageService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allpackageservice = action.payload;
      })
      .addCase(allPackageService.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })

  },

});

export default ChangeBasisSlice;

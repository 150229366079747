import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../Slice/Auth/AuthSlice";
import ChangeBasisSlice from "../Slice/Pricing/ServiceSlice";
import ServiceSlice from "../Slice/Pricing/ServiceSlice";
import PackageSlice from "../Slice/Pricing/PackageSlice";
import QuoteSlice from "../Slice/Quote/CreateQuoteSlice";



const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice.reducer,
    ChangeBasisSlice: ChangeBasisSlice.reducer,
    ServiceSlice: ServiceSlice.reducer,
    PackageSlice: PackageSlice.reducer,
    QuoteSlice: QuoteSlice.reducer,

  },
});

export default store;

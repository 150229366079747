import axios from "axios";
import * as Config from "../../Utils/Config";


export async function LOGIN(data) {
    try {
        const res = await axios.post(`${Config.base_url}login`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}

export async function agent_List(data) {
    try {
        const res = await axios.post(`${Config.base_url}agent_list`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}

export async function get_Token() {
    try {
        let data = '';
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://crmstaging.nomismasolution.co.uk/crm/staging_group/pricingtoolSyncApi/authrization.php',
            headers: {
                'Authorization': 'Bearer 5d7660dd98303a0b4e'
            },
            data: data
        };

        const res = await axios.request(config)
        return await res?.data;


    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}











import axios from 'axios';
const token  = localStorage.getItem('authClientToken');
export async function get_Token() {
    try {
        let data = '';
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://crmstaging.nomismasolution.co.uk/crm/staging_group/pricingtoolSyncApi/authrization.php',
            headers: {
                'Authorization': 'Bearer 5d7660dd98303a0b4e'
            },
            data: data
        };

        const res = await axios.request(config)
        return await res?.data;


    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}

export async function getAllClient(data) {

    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://crmstaging.nomismasolution.co.uk/crm/staging_group/pricingtoolSyncApi/getRecords.php',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

        const res = await axios.request(config)
        return await res?.data;


    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}

export async function getClientDetails(data) {

    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://crmstaging.nomismasolution.co.uk/crm/staging_group/pricingtoolSyncApi/getRecordDetails.php',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        };

        const res = await axios.request(config)
        return await res?.data;


    }
    catch (err) {
        console.log("Error Auth ", err)
        return await err;
    }
}

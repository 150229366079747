import React from "react";

const StepTwo = ({ formData, handleChange, nextStep, prevStep, currentStep}) => {
 
  console.log("currentStep", currentStep);
  return (

    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">
            New Quote{" "}
            <span>
              {" "}
              <i class="bi bi-chevron-right ms-auto"></i>
            </span>
          </h4>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body">
          <div className=" needs-validation" >
            <div className="row mb-4">
              <div className="col-md-5">
                <div className="row">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-4 col-form-label  "
                  >
                    Package type:
                  </label>
                  <div className="col-sm-8 ps-0">
                    <select className="form-select">
                      <option>Select an action</option>
                      <option value="1">Option 1</option>
                      <option value="2">Option 2</option>
                      <option value="3">Option 3</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <table className="table table-custom">
                  <thead className="">
                    <tr>
                      <th>Service</th>
                      <th>Type</th>
                      <th>Premium</th>
                      <th>Standard</th>
                      <th>Starter</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="search-form d-flex align-items-center">
                          <input
                            type="text"
                            name="query"
                            className="form-control"
                            placeholder="Search for services"
                            title="Enter search keyword"
                          />

                          <i class="bx bx-search"></i>
                        </div>
                      </td>

                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end">
                <div className="col-md-8">
                  <div className="row">
                    <div className=" col-lg-6 row ">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Discount(%):
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                    <div className="col-lg-6 row  mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Premium(%):
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                  </div>
                  <div className="row">

                    <div className="row col-lg-6  mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Yearly cost:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                    <div className="row col-lg-6  mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Payment Fee:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                  </div>
                  <div className="row">

                    <div className="row col-lg-6 mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Net @ 20% VAT:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                    <div className="row col-lg-6 mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          Net @ No VAT:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                  </div>
                  <div className="row">

                    <div className="row col-lg-6 mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label  text-end w-100 "
                        >
                          VAT:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>

                    </div>
                    <div className="row col-lg-6 mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="inputEmail3"
                          className=" col-form-label   text-end w-100 "
                        >
                          Total Including VAT (Yearly):
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input type="number" className="form-control" required />
                      </div>


                    </div>
                  </div>
                  <hr />
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-6 col-form-label  text-end  "
                        >
                          Payment Type:
                        </label>
                        <div className="col-sm-6 ps-0">
                          <select className="form-select">
                            <option>Select an action</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-6 col-form-label  text-end  "
                        >
                          Payment Frequency:
                        </label>
                        <div className="col-sm-6 ps-0">
                          <select className="form-select">
                            <option>Select an action</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between mt-3">
            {currentStep > 1 && (
              <button className="btn btn-secondary" onClick={prevStep}>
                Previous
              </button>
            )}
            <button
              className="btn btn-pink"
              // onClick={formik.handleSubmit}
            >
              {currentStep < 4 ? "Next" : "Submit"}
            </button>
          </div>
    </div>

  );
};

export default StepTwo;

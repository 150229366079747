export const ScrollToViewFirstError = (newErrors) => {
    
    if (Object.keys(newErrors).length !== 0) {
      const errorField = Object.keys(newErrors)[0];
  
      const errorElement = document.getElementById(errorField);
     
      if (errorElement) {
          const elementPosition = errorElement.getBoundingClientRect().top + window.pageYOffset;
          const offset = 80; 
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'auto'
        });
      }
    }
  }
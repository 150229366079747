import React from "react";
import { Link } from "react-router-dom";

const FormField = ({ label, type, required }) => (
    <div className="row mb-3 justify-content-center">
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-7">
            <input type={type} className="form-control" required={required} />
        </div>
    </div>
);

const FormSelect = ({ label, options, required }) => (
    <div className="row mb-3 justify-content-center">
        <label className="col-sm-4 col-form-label">{label}</label>
        <div className="col-sm-7">
            <select className="form-control" required={required}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    </div>
);

const CreateClient = () => {
    return (
        <div>
            <div className="row mb-3">
                <div className="col">
                    <h4 className="page-title">
                        <Link to="/admin/client/dashboard">
                            <i className="bx bx-arrow-back text-pink pe-1"></i>
                        </Link>
                        Client
                    </h4>
                </div>
            </div>
            <div className="card form-card">
                <div className="card-header">
                    <h5 className="card-title">New Client</h5>
                </div>
                <div className="card-body">
                    <form className="needs-validation" noValidate>
                        <div className="row">
                            <div className="col-md-6">
                                <FormField label="First Name:" type="text" required />
                                <FormField label="Email Address:" type="email" required />
                                <FormField label="Date of Birth:" type="date" required />
                                <FormField label="Address Line 1:" type="text" required />
                                <FormField label="City:" type="text" required />
                                <FormField label="Trading Name:" type="text" required />
                            </div>
                            <div className="col-md-6 ">
                                <FormField label="Last Name:" type="text" required />
                                <FormField label="Phone no:" type="text" required />
                                <FormField label="Address Line 2:" type="text" required />
                                <FormField label="Country:" type="text" required />
                                <FormField label="Trading Address:" type="text" required />
                                <FormSelect
                                    label="Client Type:"
                                    options={[
                                        { value: "individual", label: "Individual" },
                                        { value: "company", label: "Company" },
                                    ]}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-auto text-end">
                            <button className="btn btn-outline-secondary rounded">
                                Cancel
                            </button>
                            <button className="btn btn-pink rounded ms-2">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateClient;

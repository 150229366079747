import React, { useState, useEffect } from "react";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { getAllClient, getClientDetails } from "../../../Service/ThardPartyApi/ThardPartyAPI";
import { createQuote } from "../../../ReduxStore/Slice/Quote/CreateQuoteSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { EMPTY_CLIENT_NAME_ERROR, EMPTY_SECONDARY_QUOTE_TYPE_ERROR, EMPTY_BUSINESS_TYPE_ERROR, EMPTY_CONTRACT_OWNER_ERROR, EMPTY_ENQUIRY_TYPE_ERROR, EMPTY_CHANNEL_ERROR, EMPTY_QUOTE_TYPE_ERROR, ALERT_MSG_ADHOC_RECCURING_SERVICES } from "../../../Utils/Common_Error";

const StepOne = ({ formData, handleChange, nextStep, prevStep, currentStep }) => {
  const dispatch = useDispatch();
  const [allClientData, setAllClientData] = useState({});
  const [clientDetails, setClientDetails] = useState({});
  const ContractOwner = JSON.parse(localStorage.getItem("details"));

  const formik = useFormik({
    initialValues: {
      Client: "",
      SecondaryQuotetype: "",
      BusinessType: "",
      ContractOwner: "",
      EnquiryType: "",
      AdhocServices: "",
      Channel: "",
      ReccuringServices: "",
      QuoteType: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.Client) {
        errors.Client = EMPTY_CLIENT_NAME_ERROR;
      }
      if (!values.SecondaryQuotetype) {
        errors.SecondaryQuotetype = EMPTY_SECONDARY_QUOTE_TYPE_ERROR;
      }
      if (!values.BusinessType) {
        errors.BusinessType = EMPTY_BUSINESS_TYPE_ERROR;
      }
      if (!values.ContractOwner) {
        errors.ContractOwner = EMPTY_CONTRACT_OWNER_ERROR;
      }
      if (!values.EnquiryType) {
        errors.EnquiryType = EMPTY_ENQUIRY_TYPE_ERROR;
      }
      if (!values.Channel) {
        errors.Channel = EMPTY_CHANNEL_ERROR;
      }
      if (!values.QuoteType) {
        errors.QuoteType = EMPTY_QUOTE_TYPE_ERROR;
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (formik.values.AdhocServices == false && formik.values.ReccuringServices == false) {
        Swal.fire({
          icon: "warning",
          title: "Warning",
          text: ALERT_MSG_ADHOC_RECCURING_SERVICES,
          timer: 2000,
          timerProgressBar: true,
        });
        return;
      }

      const req = {
        id: "",
        Client: values.Client,
        SecondaryQuotetype: values.SecondaryQuotetype,
        BusinessType: values.BusinessType,
        ContractOwner: ContractOwner.id,
        EnquiryType: values.EnquiryType,
        AdhocServices: values.AdhocServices == true ? "1" : "0",
        Channel: values.Channel,
        ReccuringServices: values.ReccuringServices == true ? "1" : "0",
        QuoteType: values.QuoteType,
        step: 1,
        ClientDetails: clientDetails,
      };
      await dispatch(createQuote(req)).unwrap()
        .then((res) => {
          if (res.status) { 
            nextStep()
          }
          else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: res.message,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  });

  useEffect(() => {
    allClient();
  }, []);

  useEffect(() => {
    if (formik.values.Client) {
      getSingleClientDetails();
    }
  }, [formik.values.Client]);

  const allClient = async () => {
    const req = {
      agent_db: "staging_group",
      company_id: "14",
      pricing_tool_pratice_id: "580",
      module: "Contacts",
      record_id: "6013150",
    };
    await getAllClient(req).then((res) => {
      if (res.IsSuccess) {
        setAllClientData(res.result);
      } else {
        setAllClientData([]);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  const getSingleClientDetails = async () => {
    const req = {
      agent_db: "staging_group",
      company_id: 14,
      pricing_tool_pratice_id: 580,
      module: "Contacts",
      record_id: formik.values.Client || 0,
    };
    await getClientDetails(req).then((res) => {
      if (res.IsSuccess) {
        setClientDetails(res.result);
        formik.setFieldValue("BusinessType", res.result.business_type);
        formik.setFieldValue("EnquiryType", res.result.client_type);
      } else {
        setClientDetails([]);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  };

  useEffect(() => { 
    formik.setFieldValue("ContractOwner", ContractOwner.username); 
  }, []);

  const fields = [
    {
      type: "singleselect",
      name: "Client",
      label: "Client:",
      options: Object.entries(allClientData || {}).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "SecondaryQuotetype",
      label: "Secondary Quote type:",
      // options: [
      //   { value: "1", label: "Client1" },
      //   { value: "2", label: "Client2" },
      //   { value: "3", label: "Client3" },
      // ],
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "BusinessType",
      label: "Business type:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "ContractOwner",
      label: "Contract owner:",
      placeholder: "Please enter Contract owner",
      label_size: 4,
      col_size: 6,
      disable: true,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "EnquiryType",
      label: "Enquiry type:",
      // options: [
      //   { value: "1", label: "Enquiry1" },
      //   { value: "2", label: "Enquiry2" },
      //   { value: "3", label: "Enquiry3" },
      // ],
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "checkbox",
      name: "AdhocServices",
      label: "Adhoc services:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "Channel",
      label: "Channel:",
      // options: [
      //   { value: "1", label: "Channel1" },
      //   { value: "2", label: "Channel2" },
      //   { value: "3", label: "Channel3" },
      // ],
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "checkbox",
      name: "ReccuringServices",
      label: "Reccuring services:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "text",
      name: "QuoteType",
      label: "Quote type:",
      // options: [
      //   { value: "1", label: "QuoteType1" },
      //   { value: "2", label: "QuoteType2" },
      //   { value: "3", label: "QuoteType3" },
      // ],
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
  ];


  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title">
            <Link to="/admin/quote/quotes-dashboard">
              <i className='bx bx-arrow-back text-pink pe-1'></i>
            </Link>
            Quote
          </h4>
        </div>
      </div>
      <div className="card form-card">
        <div className="card-header">
          <h5 className="card-title">Create New Quote</h5>
        </div>
        <div className="card-body">
          <ReusableForm
            fieldtype={fields.filter(
              (field) => !field.showWhen || field.showWhen(formik.values)
            )}
            formik={formik}
            hide_close_btn={true}
            hide_submit_btn={true}
            test={true}
          />

          <div className="d-flex justify-content-between mt-3">
            {currentStep > 1 && (
              <button className="btn btn-secondary" onClick={prevStep}>
                Previous
              </button>
            )}
            <button className="btn btn-pink" onClick={formik.handleSubmit}>
              {currentStep < 3 ? "Next" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
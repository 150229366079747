

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import SimpleDataTable from '../../../ExtraComponents/SimpleDatatable';
import { useDispatch } from 'react-redux';
import { lastFivePackages } from "../../../ReduxStore/Slice/Pricing/PackageSlice";
import Datatable from '../../../ExtraComponents/ReusableTable1';


const PackageDashboard = () => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  const [dashboardCount, setDashboardCount] = useState([]); 
  useEffect(() => {
    LastFilvePackage();
  }, []);

  const LastFilvePackage = async () => {
    await dispatch(lastFivePackages()).unwrap()
      .then((res) => {
        if (res.status) {
          setPackages(res.data);
          setDashboardCount(res.counts);
        }
        else {
          setPackages([]);
          setDashboardCount([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const columns = [
    {
      name: "Package Name",
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => row.status === 1 ? "Active" : "Inactive",
      sortable: true,
    },
    {
      
      name: (
        <div className='w-100'>
          Applicable<br /> To
        </div>
      ),
      selector: (row) => 
        row.PackageApplicables
          .map((item) => <div className='applicable-data'>{item.Applicable.name} <i className='bx bxs-x-circle'></i></div>)
          ,
            sortable: true,
            width: "15%",

    },
    {
      name: (
        <div>
          Total Cost<br /> of Service
        </div>
      ),
      selector: (row) => row.cost_service_price,
      sortable: true,
    },
    {
      
      name: (
        <div>
          Default<br /> Price
        </div>
      ),
      selector: (row) => row.default_price,
      sortable: true,
    },
    {
      name: (
        <div>
         Threshold<br /> Price
        </div>
      ),
      
      selector: (row) => row.threshold_price,
      sortable: true,
    },
    {
      
      name: (
        <div>
          Default<br /> Discount
        </div>
      ),
      selector: (row) => row.default_discount,
      sortable: true,
    },
    {
    

      name: (
        <div>
          Threshold <br />  Discount
        </div>
      ),
      selector: (row) => row.threshold_discount,
      sortable: true,
    },
    {
      name: (
        <div>
          Final Package<br /> Price
        </div>
      ),
     
      selector: (row) => row.final_package_price,
      sortable: true,
    },
   ,




   

  ];


  return (
    <div>
      <>
        <div className="row mb-3">
          <div className="col">
            <h4 className="page-title">Packages</h4>
          </div>
          <div className="col-auto align-self-center">
            <Link to='/admin/pricing/create-package' className="btn btn-pink" id="Dash_Date">
              <span className="ay-name" id="Day_Name">
                Add New Package
              </span>
            </Link>
          </div>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Active Packages</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>{dashboardCount && dashboardCount.active}</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Inactive Packages</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>{dashboardCount && dashboardCount.inactive}</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-md-4">
                  <div className="card info-card sales-card">
                    <div className="card-body">
                      <h5 className="card-title pb-4">Total Packages</h5>
                      <div className="d-flex align-items-end justify-content-between ">
                        <div className="">
                          <h6>{dashboardCount && dashboardCount.all}</h6>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </section>
        <section className="section dashboard">
          <div className="row">
            <div className="col">
              <span className="text-pink font-16">Recent</span>
            </div>
            <div className="col-auto align-self-center">
              <Link to="/admin/pricing/all-packages" >
                <span className="text-pink font-16">See All</span>
              </Link>
            </div>
            <div className="package-table">
            <Datatable
              columns={columns}
              data={packages}
              filter={false}
            />
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default PackageDashboard;

import axios from "axios";
import * as Config from "../../Utils/Config";

export async function create_Contract_Temp(data) {
    try {
        const fromData = new FormData();
        fromData.append('agent_id', data.agent_id);
        fromData.append('name', data.formData.templateName);
        fromData.append('description', data.formData.description);
        fromData.append('category', data.formData.template_category.map((item) => item.value));
        fromData.append('subject', data.formData.subjectLine);
        fromData.append('bcc_mail', data.formData.bccEmail);
        fromData.append('reply_to', data.formData.replyTo);
        fromData.append('body', data.formData.body);
        data?.formData.attachments?.forEach((file) => {
            fromData.append('attachment', file);
        });
        const res = await axios.post(`${Config.base_url}contract/create_contract_template`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'maxContentLength': 'Infinity',
                'maxBodyLength': 'Infinity'
            },
        })
        return await res?.data;
    }

    catch (err) {
        return await err;
    }
}

export async function update_Contract_Temp(data) {
    try {
        const fromData = new FormData();
        fromData.append('agent_id', data.agent_id);
        fromData.append('name', data.formData.templateName);
        fromData.append('description', data.formData.description);
        fromData.append('category', data.formData.template_category.map((item) => item.value));
        fromData.append('subject', data.formData.subjectLine);
        fromData.append('bcc_mail', data.formData.bccEmail);
        fromData.append('reply_to', data.formData.replyTo);
        fromData.append('body', data.formData.body);
        fromData.append('id', data.id);
        data?.formData.attachments?.forEach((file) => {
            fromData.append('attachment', file);
        });
        const res = await axios.post(`${Config.base_url}contract/update_contract_template`, fromData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'maxContentLength': 'Infinity',
                'maxBodyLength': 'Infinity'
            },
        })
        return await res?.data;
    }

    catch (err) {
        return await err;
    }
}

export async function get_Tags(data) {
    try {
        const res = await axios.post(`${Config.base_url}gettemplatetags`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Common_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}getcommontemplates`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Templet_Catagry(data) {
    try {
        const res = await axios.post(`${Config.base_url}gettemplatecategory`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function all_Contract_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}contract/template_list`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function delete_Contract_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}contract/delete`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_ReplyTo(data) {
    try {
        const res = await axios.post(`${Config.base_url}replyto`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function create_Common_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}create_common_template`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}

export async function get_Single_Templet(data) {
    try {
        const res = await axios.post(`${Config.base_url}contract/get_template_by_id`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}










import axios from "axios";
import * as Config from "../../Utils/Config";



export async function create_Quote(data) {
    try {
        const res = await axios.post(`${Config.base_url}create_quote`, data, {
            data: {},
        })
        return await res?.data;
    }
    catch (err) {
        return await err;
    }
}











import React from "react";
import ReusableForm from "../../../ExtraComponents/ReusableForm1";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";


const StepThree = ({ formData, handleChange }) => {
  const navigate = useNavigate()


  const formik = useFormik({
    initialValues: {
      Client: "",
      SecondaryQuotetype: "",
      BusinessType: "",
      ContractOwner: "",
      EnquiryType: "",
      AdhocServices: "",
      Channel: "",
      ReccuringServices: "",
      QuoteType: "",
    },
    validate: (values) => {
      let errors = {};

      return errors;
    },

    onSubmit: async (values) => {
      console.log("values", values);
    }

  });

  const option = [
    { value: "1", label: "Client1" },
    { value: "2", label: "Client2" },
    { value: "3", label: "Client3" }
  ]

  const fields = [
    {
      type: "singleselect",
      name: "Client",
      label: "Client:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "singleselect",
      name: "SecondaryQuotetype",
      label: "Secondary Quote type:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "singleselect",
      name: "BusinessType",
      label: "Business type:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "text",
      name: "ContractOwner",
      label: "Contract owner:",
      placeholder: "Please enter Contract owner",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "multiselect",
      name: "EnquiryType",
      label: "Enquiry type:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "checkbox",
      name: "AdhocServices",
      label: "Adhoc services:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "singleselect",
      name: "Channel",
      label: "Channel:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

    {
      type: "checkbox",
      name: "ReccuringServices",
      label: "Reccuring services:",
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },
    {
      type: "singleselect",
      name: "QuoteType",
      label: "Quote type:",
      options: option,
      label_size: 4,
      col_size: 6,
      disable: false,
      child_col_size: 7,
    },

  ]

  return (
    <div>
      <div className="row mb-4">
        <div className="col">
          <h4 className="page-title"><Link to="/admin/quote/quotes-dashboard">
            <i className='bx bx-arrow-back text-pink pe-1'></i>
          </Link>Quote</h4>
        </div>
      </div>
      <div className="card form-card">
        <div className="card-header">
          <h5 className="card-title ">Create New Quote</h5>
        </div>
        <div className="card-body">
          <ReusableForm
            fieldtype={fields.filter(
              (field) => !field.showWhen || field.showWhen(formik.values)
            )}
            formik={formik}
            hide_close_btn={true}
            hide_submit_btn={true}
          />
        </div>
      </div>
    </div>
  );
};

export default StepThree;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    get_Service_Type,
    get_Service_ApplicableTo,
    get_Pricing_Rule,
    create_Service,
    UpdateService,

} from '../../../Service/Pricing/Service'
// const UserId = JSON.parse(localStorage.getItem("agent_id"));
const UserId = JSON.parse(localStorage.getItem("details"))?.id;



export const getServiceType = createAsyncThunk("getServiceType", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }

        const res = await get_Service_Type(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getServiceApplicable = createAsyncThunk("getServiceApplicable", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Service_ApplicableTo(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getPricingRule = createAsyncThunk("chargebasisdata", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Pricing_Rule(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const createService = createAsyncThunk("createService", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Service(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});


export const updateService = createAsyncThunk("updateService", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await UpdateService(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});



const ServiceSlice = createSlice({
    name: "ServiceSlice",
    initialState: {
        isLoading: false,
        isError: false,
        getservicetype: [],
        getserviceapplicable: [],
        getpricingrule: [],
        createservice: [],
        updateService: []

    },


    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getServiceType.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getServiceType.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getservicetype = action.payload;
            })
            .addCase(getServiceType.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getServiceApplicable.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getServiceApplicable.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getserviceapplicable = action.payload;
            })
            .addCase(getServiceApplicable.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getPricingRule.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getPricingRule.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getpricingrule = action.payload;
            })
            .addCase(getPricingRule.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(createService.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createservice = action.payload;
            })
            .addCase(createService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(updateService.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updateService = action.payload;
            })
            .addCase(updateService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
    },

});

export default ServiceSlice;

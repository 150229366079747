import React, { useState, useEffect } from 'react';
import { allQuoteTemplet, deleteQuoteTemplet } from "../../../ReduxStore/Slice/Templets/QuotesTemplets";
import { useDispatch } from 'react-redux';
import Datatable from '../../../ExtraComponents/ReusableTable';
import { useNavigate } from 'react-router-dom';
import { PenLine, Trash2 } from 'lucide-react';
import sweatalert from "sweetalert2";


const QuotesTemplets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [getAllQuoteTempletData, setAllQuoteTempletData] = useState([]);

  useEffect(() => {
    getAllQuoteTemplet();;
  }, []);

  const getAllQuoteTemplet = async () => {
    await dispatch(allQuoteTemplet()).unwrap()
      .then((response) => {
        if (response.status) {
          setAllQuoteTempletData(response.data);
        } else {
          setAllQuoteTempletData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching charge basis data:", error);
      });
  };

  const deleteQuoteTempletRow = async (row) => {
    sweatalert.fire({
      title: "Are you sure?",
      text: "You want to delete this Quote! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const req = { id: row.id };
        await dispatch(deleteQuoteTemplet(req)).unwrap()
          .then((response) => {
            if (response.status) {
              sweatalert.fire({
                title: "Success",
                text: "Quote Deleted Successfully",
                icon: "success",
                timer: 1500,
                timerProgressBar: true
              }
              );
              getAllQuoteTemplet()
            }
          })
          .catch((error) => {
            console.error("Error deleting package:", error);
          });
      } else if (result.dismiss === sweatalert.DismissReason.cancel) {
        return
      }
    });

  }


  const handleEdit = (row) => {
    navigate(`/admin/template/edit-quote-template` , {state: row});
  }

  const columns = [
    {
      name: "Template Name",
      selector: (row) => row?.name,
      sortable: true,
    },

    {
      name: "Subject",
      selector: (row) => row?.subject,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => row?.QuoteTemplateCategories?.[0]?.TemplateCategory?.name,
      sortable: true

    },
    // {
    //   name: "Status",
    //   selector: (row) => row?.cost_service_price,
    //   sortable: true,
    // },
    {
      name: "Action",
      selector: (row) => <>
        <PenLine onClick={()=>handleEdit(row)} />
        <Trash2
          onClick={() => deleteQuoteTempletRow(row)}
        />
      </>,
      sortable: true,
    },
  ];

  return (
    <div>

      <section className="section dashboard">
        <div className="row">
          <div className='row'>
            <h5 className='col-lg-10'>
              <span className="ay-name" id="Day_Name">
                Quote Templates
              </span>
            </h5>
            <div className='col-lg-2'>
              <button className="btn btn-pink rounded ms-2 d-flex float-lg-end" onClick={() => navigate('/admin/template/create-quote-template')}>
                Create New
              </button>
            </div>

          </div>
          < div className='expandable-table'>
            <Datatable
              columns={columns}
              data={getAllQuoteTempletData}
              filter={false}
            />
          </div>
        </div>
      </section>

    </div>
  );
};

export default QuotesTemplets;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    create_Contract_Temp,
    get_Tags,
    get_Common_Templet,
    get_Templet_Catagry,
    all_Contract_Templet,
    delete_Contract_Templet,
    get_ReplyTo,
    create_Common_Templet,
    get_Single_Templet,
    update_Contract_Temp
} from '../../../Service/Templets/ContractService'
import { all } from "axios";
const UserId = JSON.parse(localStorage.getItem("agent_id"));

export const createContractTemp = createAsyncThunk("contract/create_contract_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Contract_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const updateContractTemp = createAsyncThunk("contract/update_contract_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await update_Contract_Temp(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getTags = createAsyncThunk("gettemplatetags", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Tags(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getCommonTemplet = createAsyncThunk("getcommontemplates", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Common_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getTempletCategary = createAsyncThunk("gettemplatecategory", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Templet_Catagry(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const allContractTemplet = createAsyncThunk("contract/template_list", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await all_Contract_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const deleteContractTemplet = createAsyncThunk("contract/delete", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await delete_Contract_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getReplyTo = createAsyncThunk("replyto", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_ReplyTo(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const createCommonTemplet = createAsyncThunk("create_common_template", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await create_Common_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

export const getSingleTemplet = createAsyncThunk("contract/get_template_by_id", async (data) => {
    try {
        const updatedData = { ...data, agent_id: UserId }
        const res = await get_Single_Templet(updatedData);
        return await res;
    } catch (err) {
        return err;
    }
});

const ChangeBasisSlice = createSlice({
    name: "ChangeBasisSlice",
    initialState: {
        isLoading: false,
        isError: false,
        createcontracttemp: [],
        gettags: [],
        getcommontemplet: [],
        gettempletcatagary: [],
        allcontracttemplet: [],
        deletecontracttemplet: [],
        createcommontemplet: [],
        getreplyto: [],
        getsingletmplet: [],
        updatecontract : []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createContractTemp.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createContractTemp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createcontracttemp = action.payload;
            })
            .addCase(createContractTemp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getTags.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTags.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettags = action.payload;
            })
            .addCase(getTags.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getCommonTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getCommonTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getcommontemplet = action.payload;
            })
            .addCase(getCommonTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getTempletCategary.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTempletCategary.fulfilled, (state, action) => {
                state.isLoading = false;
                state.gettempletcatagary = action.payload;
            })
            .addCase(getTempletCategary.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(allContractTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(allContractTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allcontracttemplet = action.payload;
            })
            .addCase(allContractTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(deleteContractTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(deleteContractTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deletecontracttemplet = action.payload;
            })
            .addCase(deleteContractTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getReplyTo.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getReplyTo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getreplyto = action.payload;
            })
            .addCase(getReplyTo.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(createCommonTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(createCommonTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.createcommontemplet = action.payload;
            })
            .addCase(createCommonTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(getSingleTemplet.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getSingleTemplet.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getsingletmplet = action.payload;
            })
            .addCase(getSingleTemplet.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            .addCase(updateContractTemp.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateContractTemp.fulfilled, (state, action) => {
                state.isLoading = false;
                state.updatecontract = action.payload;
            })
            .addCase(updateContractTemp.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            }
        );
    },

});

export default ChangeBasisSlice;

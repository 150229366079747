import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import {LOGIN , agent_List , get_Token} from '../../../Service/Auth/AuthService'


export const Login = createAsyncThunk("login", async (data) => {
  try {  
    const res = await LOGIN(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const agentList = createAsyncThunk("agent_list", async (data) => {
  try {  
    const res = await agent_List(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const getToken = createAsyncThunk("https://crmstaging.nomismasolution.co.uk/crm/staging_group/pricingtoolSyncApi/authrization.php", async () => {
  try {  
    const res = await get_Token();
    console.log("Token ss", res)
    return await res;
  } catch (err) {
    return err;
  }
});


const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    isLoading: false,
    isError: false,
    login : [],
    agentList : []
  },
  

  reducers: {},  
  extraReducers: (builder) => {
    builder 
    .addCase(Login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(Login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.login = action.payload;
      })
      .addCase(Login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })  
      .addCase(agentList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(agentList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.agentList = action.payload;
      })
      .addCase(agentList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      
  },
   
});

export default AuthSlice;

// Add packege to show error message
export const EMPTY_PACKAGE_NAME_ERROR = "Package name is required";
export const EMPTY_APPLICABLE_TO_ERROR = "Please enter a valid applicable to";
export const EMPTY_TOTAL_COST_OF_SERVICES_ERROR = "Please enter a valid total cost of services";
export const EMPTY_DEFAULT_PACKAGE_PRICE_ERROR = "Default price is required";
export const EMPTY_DEFAULT_DISCOUNT_ERROR = "Please enter a valid default discount";
export const EMPTY_THRESHOLD_PRICE_ERROR = "Threshold price is required";
export const EMPTY_MINIMUM_PRICE_ERROR = "Minimum Price is required";
export const EMPTY_THRESHOLD_DISCOUNT_ERROR = "Please enter a valid threshold discount";
export const EMPTY_MAXIMUM_DISCOUNT_ERROR = "Please enter a valid maximum discount";


// create Service Error
export const EMPTY_NUMBER_OF_SERVICE_ERROR = "Number of services is required";
export const EMPTY_SERVICE_TYPE_ERROR = "Please Select a Service Type";
export const EMPTY_PRICE_ERROR = "Price is required";

// Create Chage Basis Error
export const EMPTY_CHARGE_BASIS_NAME_ERROR = "Charge basis name is required";
export const EMPTY_CHARGE_BASIS_TYPE_ERROR = "Basis Type is required";
export const EMPTY_NAME_OF_UNIT_ERROR = "Name of unit is required";
export const EMAPTY_TAGS_ERROR = "Tags are required"
export const EMAPTY_BAND_VALUE_ERROR = "Value field is required"
export const EMAPTY_PRICE_PER_UNIT_ERROR = "Price per unit is required";
export const EMPTY_MIN_BAND_BASIS_ERROR = "Min value is required";
export const EMPTY_MAX_BAND_BASIS_ERROR = "Max value is required"; 
export const EMPTY_FREQUENCY_NAME_ERROR = "Frequency name is required";
export const EMPTY_FREQUENCY_VALUE_ERROR = "Frequency value is required";
export const EMPTY_YESNO_ERROR = "Value field is required";

// Quote templet Error
export const EMPTY_TEMPLET_NAME_ERROR = "Name is required";
export const EMPTY_TEMPLET_CATEGORY_ERROR = "Category is required";
export const EMPTY_COMMON_TEMPLET_NAME_ERROR = "Heading is required";
export const EMPTY_TRIGGER_EVENT_ERROR = "Trigger Event is required";


// Email Templet Error
export const EMPTY_EMAIL_NAME_ERROR = "Name is required";


// Quote Error
export const EMPTY_CLIENT_NAME_ERROR = "Please select Client";
export const EMPTY_SECONDARY_QUOTE_TYPE_ERROR = "Please enter Secondary Quote type";
export const EMPTY_BUSINESS_TYPE_ERROR = "Please enter Business type";
export const EMPTY_CONTRACT_OWNER_ERROR = "Please enter Contract owner";
export const EMPTY_ENQUIRY_TYPE_ERROR = "Please enter Enquiry type";
export const EMPTY_CHANNEL_ERROR = "Please enter Channel";
export const EMPTY_QUOTE_TYPE_ERROR = "Please enter Quote type";
export const ALERT_MSG_ADHOC_RECCURING_SERVICES = "Please select Adhoc services or Reccuring services";






import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReusableModal from "../ExtraComponents/ReusableModal";
import { useDispatch } from "react-redux";
import { agentList } from "../ReduxStore/Slice/Auth/AuthSlice";
import {jwtDecode } from "jwt-decode";

const AdminHeader = () => {
  const userDetails = JSON.parse(localStorage.getItem("details"));
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [showplusModal, setPlusshowModal] = useState(false);
  const [showpencilModal, setPencilshowModal] = useState(false);
  const [agentListData, setAgentListData] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const handlePlusShowModal = () => setPlusshowModal(true);
  const handlePencilShowModal = () => setPencilshowModal(true);
  const handleCloseModal = () => {
    setPlusshowModal(false);
    setPencilshowModal(false);
  };

  
  const navigate = useNavigate();
  useEffect(() => {
    getAgentList();
  }, []);

  const getAgentList = async () => {
    const req = { user_id: userDetails.id }
    await dispatch(agentList(req)).unwrap()
      .then((res) => {
        if (res.status) {
          setAgentListData(res.data)
          setSelectedAgent(res.data[0].Agent.id)
          localStorage.setItem("agent_id", res.data[0].Agent.id)
        }
        else {
          setAgentListData([])
        }
      })
      .catch((err) => {
        console.log("Error", err)
      })
  }

  useEffect(() => {
    localStorage.setItem("agent_id", selectedAgent)
  }, [selectedAgent])

  const clearSession = () => {
    var decoded = jwtDecode(token);
    if (decoded.exp * 1000 < new Date().getTime()) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    }
};

useEffect(() => {
    clearSession();
}, []);

  return (
    <div>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <a
            href="index.html"
            className="logo d-flex align-items-center justify-content-center"
          >
            <span className="d-none d-lg-block">
              {" "}
              <img src="/assets/img/logo.png" alt="" />
            </span>
          </a>
        </div>
        <div className="header-select d-flex">
          <select
            className="form-select"
            id="floatingSelect"
            aria-label="Floating label select example"
            onChange={(e) => { setSelectedAgent(e.target.value) }}
            value={selectedAgent}
          >
            {agentListData.map((item, index) => {
              return <option key={index} value={item.Agent.id}>{item.Agent.agent_name}</option>
            }
            )}
          </select>
          {/* <button className="btn p-0 border-0" onClick={handlePlusShowModal}>
            <i class="bi bi-plus-circle-fill fs-4 ms-3 text-muted"></i>
          </button>
          <button className="btn p-0 border-0" onClick={handlePencilShowModal}>
            <i class="ri-pencil-line fs-4 ms-3 text-muted"></i>
          </button> */}
        </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search" />
              </a>
            </li>

            <>
              <li className="nav-item dropdown">
                <a
                  className="nav-link nav-icon"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <img src="/assets/img/notification.png" alt="" />
                </a>
                {/* End Notification Icon */}
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li className="dropdown-header">
                    You have 4 new notifications
                    <a href="#">
                      <span className="badge rounded-pill bg-pink p-2 ms-2">
                        View all
                      </span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-exclamation-circle text-warning" />
                    <div>
                      <h4>Lorem Ipsum</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>30 min. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-x-circle text-danger" />
                    <div>
                      <h4>Atque rerum nesciunt</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>1 hr. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-check-circle text-success" />
                    <div>
                      <h4>Sit rerum fuga</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>2 hrs. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="notification-item">
                    <i className="bi bi-info-circle text-primary" />
                    <div>
                      <h4>Dicta reprehenderit</h4>
                      <p>Quae dolorem earum veritatis oditseno</p>
                      <p>4 hrs. ago</p>
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-footer">
                    <a href="#">Show all notifications</a>
                  </li>
                </ul>
                {/* End Notification Dropdown Items */}
              </li>
              {/* End Notification Nav */}

              <li className="nav-item dropdown pe-3">
                <a
                  className="nav-link nav-profile d-flex align-items-center pe-0"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <i className="bi bi-person"></i>
                </a>
                {/* End Profile Iamge Icon */}
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                  <li className="dropdown-header">
                    <h6>Kevin Anderson</h6>
                    <span>Web Designer</span>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="users-profile.html"
                    >
                      <i className="bi bi-person" />
                      <span>My Profile</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="users-profile.html"
                    >
                      <i className="bi bi-gear" />
                      <span>Account Settings</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center"
                      href="pages-faq.html"
                    >
                      <i className="bi bi-question-circle" />
                      <span>Need Help?</span>
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("details");
                        navigate("/login");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-box-arrow-right" />
                      <span>Sign Out</span>
                    </a>
                  </li>
                </ul>
                {/* End Profile Dropdown Items */}
              </li>
              {/* End Profile Nav */}
            </>
          </ul>
        </nav>
      </header>
      <ReusableModal
        show={showplusModal}
        onClose={handleCloseModal}
        title={
          <>
            <i className="bi bi-filter" style={{ marginRight: "8px" }}></i>
            Filters
          </>
        }
        body={
          <div>
            <div className="row mb-4">
              <p className="text-semidark mb-1 font-14">Add Filters</p>
              <div className="col-sm-12">
                <input className="form-control " type="text" />
              </div>
            </div>
          </div>
        }
        footer={
          <>
            <button
              className="btn btn-outline-secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-pink"
              onClick={() => alert("Changes Saved!")}
            >
              Apply
            </button>
          </>
        }
      />
      <ReusableModal
        show={showpencilModal}
        onClose={handleCloseModal}
        title={
          <>
            <i className="bi bi-filter" style={{ marginRight: "8px" }}></i>
            Filters
          </>
        }
        body={
          <div>
            <div className="row mb-4">
              <p className="text-semidark mb-1 font-14">Status</p>
              <div className="col-sm-12 d-flex">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck1"
                  />
                  <label className="form-check-label" htmlFor="gridCheck1">
                    Created
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck2"
                    defaultChecked=""
                  />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    Reviewed
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck2"
                    defaultChecked=""
                  />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    Accepted
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck2"
                    defaultChecked=""
                  />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    Rejected
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck2"
                    defaultChecked=""
                  />
                  <label className="form-check-label" htmlFor="gridCheck2">
                    Sent
                  </label>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <p className="text-semidark mb-1 font-14">Date</p>
              <div className="col-sm-4">
                <input className="form-control date-feild" type="date" />
              </div>
            </div>
            <div className="row">
              <p className="text-semidark mb-1 font-14">Date Range</p>
              <div className="col-sm-4">
                <input className="form-control date-feild" type="date" />
              </div>
              <div className="col-sm-4">
                <input className="form-control date-feild" type="date" />
              </div>
            </div>
          </div>
        }
        footer={
          <>
            <button
              className="btn btn-outline-secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-pink"
              onClick={() => alert("Changes Saved!")}
            >
              Apply
            </button>
          </>
        }
      />
    </div>
  );
};

export default AdminHeader;

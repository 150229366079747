import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin_Route from './AdminRoute';
import Login from '../Auth/Login';
import { useLocation, useNavigate } from 'react-router-dom';

const Main_Route = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem('token');
    const details = JSON.parse(localStorage.getItem('details'))

  

    useEffect(() => {
        if (token == null || details == null) {
            navigate("/login")
            return;
        }

        if (location.pathname.startsWith("/updatepassword")) {
            navigate(location.pathname);
            return;
        }
        if (location.pathname === "/register") {
            navigate("/register");
            return;
        }


        if (location.pathname === "/") {
            navigate("/login");
            return;
        }

        if (location.pathname === "/forget") {
            navigate("/forget");
            return;
        }

        if (token != null && details != null && (location.pathname === "/login" || location.pathname === "/" || !location.pathname.startsWith("/admin"))) {

            navigate("/admin/dashboard");
            return;
        }

    }, [token, details]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/admin/*" element={<Admin_Route />} />
        </Routes>
    );
};

export default Main_Route;
